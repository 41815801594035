<!-- Fail Screen -->
<div class="row" *ngIf="showUserDetail === false">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <div class="col-md-12">
      <h2 class="mb-4 c-form-title">
        {{ "passwordRecoveryFailure.assistanceTitle" | cxTranslate }}
      </h2>
      <p class="text-fixed-medium">
        {{ "passwordRecoveryFailure.assistanceMessage" | cxTranslate }}
      </p>
      <p class="text-fixed-medium">
        {{ "forgotUserPassword.emailAddress" | cxTranslate }}:
        <b>{{ userInfo?.email }}</b>
      </p>
    </div>
  </div>
</div>

<!--Success screen-->
<div class="row" *ngIf="showUserDetail">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <div class="col-md-12">
      <h2 class="mb-4 c-form-title" *ngIf="userData?.forgotUsername">
        {{ "passwordRecoveryFailure.emailInfoMessage" | cxTranslate }}
      </h2>
      <h2 class="mb-4 c-form-title" *ngIf="userData?.forgotPassword">
        {{ "passwordRecoveryFailure.passwordLinkInfoMessage" | cxTranslate }}
      </h2>
      <p class="text-fixed-medium">
        {{ "forgotUserPassword.emailAddress" | cxTranslate }}:
        <b>{{ userData.email }}</b>
      </p>
      <p class="text-fixed-medium">
        {{ "passwordRecoveryFailure.accountNumber" | cxTranslate }}:
        <b>{{ userData.accountNumber }}</b>
      </p>
      <p class="text-fixed-medium">
        {{ "passwordRecoveryFailure.name" | cxTranslate }}:
        <b>{{ userData.name }}</b>
      </p>
    </div>
  </div>
</div>
