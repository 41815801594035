import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly occEndPoint: OccEndpointsService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let index = this.occEndPoint.getBaseUrl().indexOf('occ');
        if (err instanceof HttpErrorResponse) {
          if (
            err.status === 401 &&
            err.url ==
              this.occEndPoint.getBaseUrl().substring(0, index) +
                'authorizationserver/oauth/token'
          ) {
            window.location.reload();
            localStorage.removeItem('id_token');
          }
        }
        return throwError(err);
      })
    );
  }
}
