<div class="OTP-modal-container">
  <div class="modal-header d-flex align-items-center">
    <h3 *ngIf="!isLimitReached"> Verification Step </h3>
    <h3 *ngIf="isLimitReached"> Add credit card locked </h3>
    
    <i
      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mb-2"
      (click)="closeModal()"
    ></i>
  </div>

  <span *ngIf="!isLimitReached else limitReached">
    <div class="modal-body">
      <p> We sent you a verification code to your email address. Please check your email and come back and click
        “Continue” to proceed. </p>
      <p> {{ mfa?.maskedEmail }} </p>
      <div class="mt-3">
        <span class="header" *ngIf="!timedOut">Enter 6-digit verification code</span>
        <span class="header" *ngIf="timedOut">Request another 6-digit verification
          code</span>
        <form [formGroup]="otpForm" (ngSubmit)="continue()">
          <input #input1 formControlName="input1" type="text" class="OTP-input" maxlength="1" id="input1"
            (input)="(input1.value.length == 1 && input2.value.length < 1) ? input2.focus() : ''"
            (paste)="inputTriggers('paste', $event, 1)" (keydown)="inputTriggers('delete', $event, 1)">
          <input #input2 formControlName="input2" type="text" class="OTP-input" maxlength="1" id="input2"
            (input)="(input2.value.length == 1 && input3.value.length < 1) ? input3.focus() : ''"
            (paste)="inputTriggers('paste', $event, 2)" (keydown)="inputTriggers('delete', $event, 2)">
          <input #input3 formControlName="input3" type="text" class="OTP-input" maxlength="1" id="input3"
            (input)="(input3.value.length == 1 && input4.value.length < 1) ? input4.focus() : ''"
            (paste)="inputTriggers('paste', $event, 3)" (keydown)="inputTriggers('delete', $event, 3)">
          <input #input4 formControlName="input4" type="text" class="OTP-input" maxlength="1" id="input4"
            (input)="(input4.value.length == 1 && input5.value.length < 1) ? input5.focus() : ''"
            (paste)="inputTriggers('paste', $event, 4)" (keydown)="inputTriggers('delete', $event, 4)">
          <input #input5 formControlName="input5" type="text" class="OTP-input" maxlength="1" id="input5"
            (input)="(input5.value.length == 1 && input6.value.length < 1) ? input6.focus() : ''"
            (paste)="inputTriggers('paste', $event, 5)" (keydown)="inputTriggers('delete', $event, 5)">
          <input #input6 formControlName="input6" type="text" class="OTP-input" maxlength="1" id="input6"
            (paste)="inputTriggers('paste', $event, 6)" (keydown)="inputTriggers('delete', $event, 6)">
          <br>
          <p *ngIf="formError" class="error"> Incorrect verification code. </p>
          <p *ngIf="timedOut" class="error"> Sorry, you need to request a new verification code </p>
          <div class="mb-3" *ngIf="!timedOut">
            <span>Time remaining:<span> {{ timeRemaining | async | date:'mm:ss' }} </span></span>
          </div>
        </form>
      </div>
    </div>
    <p class="success" *ngIf="requestNewClicked && !timedOut">
      A new verification code was sent to your email!
    </p>
    <table class="col-12">
      <tr class="col-12" align="center">
        <button type="submit" *ngIf="!timedOut" class="c-button c-button--primary otp-button mb-3" (click)="continue()"
          [disabled]="true && otpForm.invalid || disableContinue">
          Continue
        </button>
        <button *ngIf="timedOut" class="c-button c-button--primary otp-button mb-3" (click)="requestNew('requestNew')"
          [disabled]="disableRequestNew">
          Resend verification code
        </button>
      </tr>
      <tr class="col-12" align="left">
        <p>Have not received a code? Please check your spam or junk mail</p>
      </tr>
      <tr class="col-12" align="center" *ngIf="!timedOut">
        <button class="c-button c-button--secondary otp-button" [ngClass]="timerNotif ? '' : 'mb-4'" (click)="requestNew('reset')"
          [disabled]="disableRequestNew">
          Request a new code
        </button>
        <p *ngIf="!timedOut && timerNotif">(30 seconds until you can request a new code)</p>
      </tr>
    </table>
  </span>
</div>


<ng-template #limitReached>
  <div class="modal-body">
    <p> You have reached the maximum number of attempts to verify your account, you will have to wait 24 hours to try
      again </p>

    <p class="secondary-header"> Need immediate assistance? </p>
    <p class="tertiary-header mb-0"> Customer Support</p>
    <p class="mb-5"> Monday - Friday
      <br>
      8:30am - 6:00 PM (ET)
      <br>

    </p>
  </div>
  <table class="col-12">
    <tr class="col-12" align="center">
      <button class="c-button c-button--primary mb-3" (click)="closeModal()">
        Close
      </button>
    </tr>
  </table>
</ng-template>