import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CustomBannerComponent } from './custom-banner.component';

@NgModule({
  declarations: [CustomBannerComponent],
  imports: [CommonModule, RouterModule, GenericLinkModule, MediaModule],
  providers: [
    provideConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: CustomBannerComponent,
        },
        BannerComponent: {
          component: CustomBannerComponent,
        },
        SimpleBannerComponent: {
          component: CustomBannerComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class CustomBannerModule {}
