import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subscription, timer } from 'rxjs';
import { map, take, takeWhile } from 'rxjs/operators';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { MultiFactorAuthService } from 'src/app/shared/services/multi-factor-auth.service';


import * as _ from 'underscore';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements AfterContentInit {
  @Input() mfa: any = {}
  @Input() isLimitReached: boolean;
  formError: boolean;
  disableContinue: boolean;
  disableRequestNew: boolean;
  requestNewClicked: boolean;
  timeRemaining: any;
  timedOut: boolean;
  otpForm: FormGroup;
  otpControl: any;
  subscription: Subscription;
  baseSiteId: string;
  counter = 0;
  timerNotif: boolean

  constructor(
    private mfaService: MultiFactorAuthService,
    private otpModal: NgbActiveModal,
    private baseSiteService: BaseSiteService,
    private globalMessageService: GlobalMessageService,
    private dataLayer: DataLayerService
    ) {
    this.otpForm = new FormGroup({
      input1: new FormControl('', Validators.required),
      input2: new FormControl('', Validators.required),
      input3: new FormControl('', Validators.required),
      input4: new FormControl('', Validators.required),
      input5: new FormControl('', Validators.required),
      input6: new FormControl('', Validators.required)
    })
    
    this.baseSiteService.getActive().pipe(take(1)).subscribe(res => { this.baseSiteId = res });
  }

  ngAfterContentInit(): void {
    this.triggerTimer();
    !!this.isLimitReached
    ? this.dataLayerCreate('verification_error', 'attempt limit reached')
    : this.dataLayerCreate('verification_start');
  }

  continue() {
      this.otpControl = this.otpForm.controls;
      this.disableContinue = true;
      this.otpForm.disable();
      this.triggerDisable('continue');
      
      const otp =
        this.otpControl.input1?.value
      + this.otpControl.input2?.value
      + this.otpControl.input3?.value
      + this.otpControl.input4?.value
      + this.otpControl.input5?.value
      + this.otpControl.input6?.value;

      this.mfaService.validateOTP(otp).subscribe( res => {
        if (res == 'Valid') {
          this.dataLayerCreate('verification_submit')
          this.otpModal.close('success')
        };
        if (res == 'Invalid') {
          this.dataLayerCreate('verification_submit', 'wrong code')
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR)
          this.counter++;
            if (this.counter == 3) this.triggerDisable('3attempts');
          this.formError = true;
        }
      })
    }

  requestNew(params?) {
    if (this.mfa.maxStep == this.mfa.showStep) {
      this.triggerDisable('limitReached')
      this.dataLayerCreate('verification_error', 'attempt limit reached')
    }
    if (this.mfa.maxStep > (this.mfa.showStep - 1)) {
      this.formError = false;
      if(params === 'reset') this.triggerDisable('requestNew');
      this.populateForm([], 1)
      this.mfaService.requestNewOTP().subscribe(res => {
        if (!_.isEmpty(res)) {
          this.mfa = res;
          this.triggerTimer();
          this.otpForm.enable();
          this.counter = 0;
          !res[`limited`] ? this.dataLayerCreate('verification_resent_code'): null;
        }
      });
    }
  }

  triggerTimer() {
    this.timedOut = false;
    const currentTime = new Date().getTime();
    
      setTimeout(() => {
        const milliseconds = (this.mfa.expirationTime - currentTime) > this.mfa.milliseconds && !_.isEmpty(this.mfa.milliseconds) ?
          this.mfa.milliseconds : (this.mfa.expirationTime - currentTime);

        this.timeRemaining = timer(0, 1000).pipe(
          map(n => ((milliseconds / 1000) - n) * 1000),
          takeWhile(n => n >= 0),
        );
        this.triggerDisable('timedOut')
      });
  }

  populateForm(data,inputNumber) {
    let x = 0;
    for(let i = inputNumber; i <= 6; i++) {
      const inpt = `input${i}`;
      this.otpForm.controls[`${inpt}`].setValue(data[x]);
      x++;
    }
  }

  closeModal(){
    this.dataLayerCreate('verification_error', 'closed modal')
    this.otpModal.dismiss();
  }

  inputTriggers(type, event, inputNumber?){
    switch (type){
      
      case 'paste':
      const data = event.clipboardData.getData('text').split('');
      this.populateForm(data,inputNumber)      
      break
      
      case 'delete':
        if (event.keyCode == 8 || event.keyCode == 46) {
          if(_.isEmpty(this.otpForm.controls[`input${inputNumber}`].value)) {
            if (inputNumber != 1) document.querySelectorAll<HTMLInputElement>(`#input${inputNumber-1}`)[0].focus();
          } else {
            this.otpForm.controls[`input${inputNumber}`].setValue('');
          }
        }
      break
    }
  }

  triggerDisable(params) {
    switch (params){
      
      case 'continue':
        this.formError = false;
        this.requestNewClicked = false;
        this.timedOut = false;
        setTimeout(() => {
          this.disableContinue = false;
          if (!this.timedOut) this.otpForm.enable();
        }, 2000);
      break;
      
      case 'requestNew':
        this.disableRequestNew = true;
        this.requestNewClicked = true;
        this.timerNotif = true;
        setTimeout(() => {
          this.disableRequestNew = false;
          this.timerNotif = false;
        }, 30000);
      break;

      case 'timedOut': 
        this.subscription?.unsubscribe();
        this.subscription = this.timeRemaining.subscribe(x => {
          if (x < '1000') {
            this.dataLayerCreate('verification_error', 'time expired')
            this.timedOut = true;
            this.otpForm.disable();
          }
        });
      break;
      
      case '3attempts':
        this.timedOut = true;
      break

      case 'limitReached':
        this.isLimitReached = true;
      break
    }
  }

  dataLayerCreate(event, errorType?) {
    const baseData = {
        event: event,
        interaction_type: 'authentication',
        method: 'email',
        process_type: 'add_new_credit_card'
    }

    if(errorType) {
      baseData['error_type'] = errorType
    }

    this.dataLayer.viewOTPDataLayer(baseData)
  }
}
