import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorAuthService {

  constructor(
    private baseService: BaseService,
    private readonly http: HttpClient,
    private occEndPoint: OccEndpointsService
  ) { }

  public requestNewOTP() {
    return this.http.get(this.occEndPoint.buildUrl('/new'));
  }

  public validateOTP(otp) {
    const params = {otp: otp}
    return this.http.post(this.occEndPoint.buildUrl(`/validate?otp=${otp}`),
    params, {
      responseType: 'text'
    });
  }

}
