import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-help-hint-recovery-question',
  templateUrl: './help-hint-recovery-question.component.html',
  styleUrls: ['./help-hint-recovery-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpHintRecoveryQuestionComponent implements OnInit, OnDestroy {
  userData: any;
  userInfo: any;
  showUserDetail = false;
  answerSubscription: any;
  userDataSubscription: any;

  constructor(
    private readonly forgotUserPasswordService: ForgotUserPasswordService
  ) {}

  ngOnInit() {
    this.answerSubscription = this.forgotUserPasswordService.validateAnswer$.subscribe((res) => {
      if (res !== null) {
        this.userData = res;
        this.showUserDetail =
          this.userData && this.userData.email ? true : false;
        if (!this.userData.email) {
          this.getUserData();
        }
      } else {
        this.getUserData();
      }
    });
  }

  getUserData() {
  this.userDataSubscription = this.forgotUserPasswordService.userData$.subscribe((res) => {
      this.userInfo = res;
    });
  }

  ngOnDestroy() {
    this.forgotUserPasswordService.validateEmail.next(null);
    this.forgotUserPasswordService.validateAnswer.next(null);
    this.answerSubscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
  }
}
