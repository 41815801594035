import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsmComponentsModule } from '@spartacus/asm/components';
import {
  AuthService,
  I18nModule,
  provideConfig,
  WindowRef,
} from '@spartacus/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { FormErrorsModule } from '@spartacus/storefront';
import { VscaAsmMainUiComponent } from './components/vsca-asm-main-ui/vsca-asm-main-ui.component';
import { FormatTimerPipe } from './components/vsca-asm-session-timer/format-timer.pipe';
import { VscaAsmSessionTimerComponent } from './components/vsca-asm-session-timer/vsca-asm-session-timer.component';
import { VscaCSAgentLoginFormComponent } from './components/vsca-csagent-login-form/vsca-csagent-login-form.component';
import { VscaCustomerEmulationComponent } from './components/vsca-customer-emulation/vsca-customer-emulation.component';
import { VscaCustomerSelectionComponent } from './components/vsca-customer-selection/vsca-customer-selection.component';
import { defaultVscaAsmLayoutConfig } from './default-vsca-asm-layout.config';
import { VscaAsmAuthStorageService } from './service/vsca-asm-auth-storage.service';
import { VscaAsmComponentService } from './service/vsca-asm-component.service';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule],
  declarations: [
    VscaAsmMainUiComponent,
    FormatTimerPipe,
    VscaCSAgentLoginFormComponent,
    VscaCustomerEmulationComponent,
    VscaCustomerSelectionComponent,
    VscaAsmSessionTimerComponent,
  ],
  providers: [
    provideConfig(defaultVscaAsmLayoutConfig),
    {
      provide: VscaAsmComponentService,
      useClass: VscaAsmComponentService,
      deps: [AuthService, CsAgentAuthService, WindowRef],
    },
    {
      provide: VscaAsmAuthStorageService,
      useClass: VscaAsmAuthStorageService,
    },
  ]
})
export class VscaAsmComponentsModule extends AsmComponentsModule {}
