<section class="checkout-popover">
  <section class="checkout-modal">
    <div class="close" (click)="dismissModal()">
      <i
        class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
      ></i>
    </div>
    <div class="modal-header overlay-heading">
      <h3 class="font-bold u-border-bottom-grey pb-4 w-100">
        {{ 'externalpopover.modalheader' | cxTranslate }}
      </h3>
    </div>

    <div class="modal-body confirm-order pb-4">
      <p class="text-fixed-medium">
        {{ 'externalpopover.modalContentBody' | cxTranslate }}
      </p>
      <div class="row">
        <div class="col-md-6">
          <button
            type="button"
            class="c-button c-button--secondary w-100"
            (click)="dismissModal()"
          >
            {{ 'externalpopover.modalBluebtn' | cxTranslate }}
          </button>
        </div>
        <div class="col-md-6">
          <button
            type="submit"
            class="c-button c-button--primary w-100"
            (click)="redirectto()"
          >
            {{ 'externalpopover.modalWhiteBtn' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </section>
</section>
