export const TwoThousand = 2000;
export const Thousand = 1000;
export const TwentyFour = 24;
export const Sixty = 60;
export const NumberTen = 10;
export const NumberEight = 8;
export const NumberSix = 6;
export const NumberFive = 5;
export const NumberFour = 4;
export const NumberThree = 3;
export const NumberTwo = 2;
