import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-user-data-recovery-action',
  templateUrl: './user-data-recovery-action.component.html',
  styleUrls: ['./user-data-recovery-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDataRecoveryActionComponent implements OnInit {
  passwordAnswerForm: FormGroup;
  userDataRecoveryQuestion: any;
  userInfo: any;
  answerRequired = false;
  validateEmailSubscription: any;
  dataSubscription: any;
  validateAnswerSubscription: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly forgotUserPasswordService: ForgotUserPasswordService,
    private readonly route: RoutingService
  ) {}

  ngOnInit() {
    this.passwordAnswerForm = this.fb.group({
      answer: ['', [Validators.required]],
    });
    this.validateEmailSubscription = this.forgotUserPasswordService.validateEmail$.subscribe((res) => {
      this.userDataRecoveryQuestion = res;
    });
    this.dataSubscription = this.forgotUserPasswordService.userData$.subscribe((res) => {
      this.userInfo = res;
    });
  }

  ngOnDestroy(){        
    this.validateEmailSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    if(this.validateAnswerSubscription){
      this.validateAnswerSubscription.unsubscribe();
    }
  }

  validateAnswer(){
    if(!this.passwordAnswerForm.controls.answer.errors?.required){
      this.answerRequired = false;
    }
  }

  onSubmit() {
    if(this.passwordAnswerForm.controls.answer.errors?.required){
      this.answerRequired = true;
      return false;
    }
    if (this.userDataRecoveryQuestion !== null) {
      const params: any = {
        accountNumber: '',
        email: this.userInfo.email ? this.userInfo.email : '',
        forgotPassword: this.userInfo.email ? this.userInfo.forgotPassword : '',
        forgotUsername: this.userInfo.email ? this.userInfo.forgotUsername : '',
        name: '',
        passwordAnswer: this.passwordAnswerForm.value.answer,
        passwordQuestion: this.userDataRecoveryQuestion.passwordQuestion,
      };
      this.forgotUserPasswordService.validatePasswordAnswer(params);
      this.validateAnswerSubscription = this.forgotUserPasswordService.validateAnswer$.subscribe((res) => {
        if (res !== null) {
          this.route.goByUrl('/login/helphintquestionrecovery');
        }
      });
    }
    return true;
  }
}
