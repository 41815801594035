import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { BackToTopComponent } from './back-to-top.component';
import { I18nModule, provideConfig } from '@spartacus/core';
import { translationChunksConfig } from '@spartacus/assets';
 
@NgModule({
  declarations: [BackToTopComponent],
  imports: [
    CommonModule,
    I18nModule, // Import the I18nModule to enable translation functionality
  ],
  exports: [BackToTopComponent],
  providers: [
    provideOutlet({
      id: 'Footer',
      position: OutletPosition.AFTER,
      component: BackToTopComponent,
    }),
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json', // Path for translation JSON files
        },
        chunks: translationChunksConfig, // Include translation chunks config
      },
    }),
  ],
})
 
export class BackToTopModule {}
