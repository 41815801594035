import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ResourcesService } from '../resources.service';
import { LanguageService } from '@spartacus/core';

@Component({
  selector: 'app-browse-by-topic',
  templateUrl: './browse-by-topic.component.html',
  styleUrls: ['./browse-by-topic.component.scss']
})
export class BrowseByTopicComponent implements OnInit, OnDestroy {

  catalogTypes = [];
  list = [];
  selectedtype;
  catalogTypeSubscription: any;

  constructor(
    public service: ResourcesService,
    private readonly cd: ChangeDetectorRef,
    private readonly lang: LanguageService
  ) { }

  ngOnInit() {  
    this.lang.getActive().subscribe((res) => {
      this.getCatalogTypes(res);
    });      
  }

  getCatalogTypes(type){
    this.service.getCatalogs();
    this.catalogTypeSubscription = this.service.catalogTypes$.subscribe((res) => {
      if(res !== null){
        this.catalogTypes = res.subcategories;
        this.catalogTypes = type == 'fr' ?  [{name: 'Tout', id:'m1'}].concat(this.catalogTypes) : [{name: 'All', id:'m1'}].concat(this.catalogTypes);
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy(){    
    this.catalogTypeSubscription.unsubscribe();
  }

  getCatalogType(type){
    this.service.getCatalogTypes(type);
  }

}
