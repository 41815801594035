<section class="col-md-8 col-sm-8 pay-section float-left account-reg-section mb-4">
  <app-user-header [selectedHeader]="userData?.header" [tableCategory]="creditInvoice"></app-user-header>
  <div class="row">
    <div class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        confirmation-split
      ">

      <h2 class="mb-4 c-form-title">
        {{ "userRegistration.userConfirmation.title" | cxTranslate }}
        {{ message?.title }}
      </h2>

      <h4 class="mb-4 c-form-copy">{{
        "userRegistration.userConfirmation.content" | cxTranslate
        }}</h4>
      <div class="invoice-btngrps">
        <button type="button" class="c-button c-button--primary c-button--lg reviewvalidbtn"
          data-di-id="di-id-41a05d18-6b04a424" [routerLink]="['/Open-Catalogue/c/1']">
          {{ "userRegistration.userConfirmation.catalog_button" | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</section>