import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseSiteService, getLastValueSync, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-forgot-user-password',
  templateUrl: './forgot-user-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotUserPasswordComponent implements OnInit, OnDestroy {
  passwordResetForm: FormGroup;
  recoverPasswordForm: FormGroup;
  recoverPasswordrequired = false;
  passwordResetRequired = false;
  emailRegex: any =
    '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';
  emailSubscription: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly forgotUserPasswordService: ForgotUserPasswordService,
    private readonly route: RoutingService,
    protected baseSiteService: BaseSiteService
  ) {}

  isCaptchaEnabled$: Observable<boolean> = this.baseSiteService.get().pipe(
    map((baseSite: any) => baseSite?.captchaConfig?.enabled)
  );

  ngOnInit() {
    this.passwordResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      recaptcha: ['', getLastValueSync(this.isCaptchaEnabled$) ? Validators.required : Validators.nullValidator],
    });
    this.recoverPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      recaptcha: ['', getLastValueSync(this.isCaptchaEnabled$) ? Validators.required : Validators.nullValidator],
    });
  }

  submitrecoverPasswordForm() {
    this.recoverPasswordForm.markAllAsTouched();
    if (this.recoverPasswordForm.invalid) {
      if (this.recoverPasswordForm.controls.email.errors?.required) {
        this.recoverPasswordrequired = true;
      }
      return false;
    }
    const params: any = {
      accountNumber: '',
      email: this.recoverPasswordForm.value.email,
      forgotPassword: false,
      forgotUsername: true,
      name: '',
      passwordAnswer: '',
      passwordQuestion: '',
    };
    this.forgotUserPasswordService.validateEmailId(params, this.recoverPasswordForm.value.recaptcha);
    this.emailSubscription =
      this.forgotUserPasswordService.validateEmail$.subscribe((res) => {
        this.gotoQuestionPasswordPage(res);
      });
    return true;
  }

  ngOnDestroy() {
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
  }

  validateRecoverPassword() {
    if (!this.recoverPasswordForm.controls.email.errors?.required) {
      this.recoverPasswordrequired = false;
    }
  }

  validatepasswordReset() {
    if (!this.passwordResetForm.controls.email.errors?.required) {
      this.passwordResetRequired = false;
    }
  }

  gotoQuestionPasswordPage(questionInfo: any) {
    if (questionInfo !== null) {
      if (questionInfo && questionInfo.passwordQuestion) {
        this.route.goByUrl('/login/userdatarecoveryaction');
      } else {
        this.route.goByUrl('/login/helphintquestionrecovery');
      }
    }
  }

  submitpasswordResetForm() {
    this.passwordResetForm.markAllAsTouched();
    if (this.passwordResetForm.invalid) {
      if (this.passwordResetForm.controls.email.errors?.required) {
        this.passwordResetRequired = true;
      }
      return false;
    }
    const params: any = {
      accountNumber: '',
      email: this.passwordResetForm.value.email,
      forgotPassword: true,
      forgotUsername: false,
      name: '',
      passwordAnswer: '',
      passwordQuestion: '',
    };
    this.forgotUserPasswordService.validateEmailId(params, this.passwordResetForm.value.recaptcha);
    this.emailSubscription =
      this.forgotUserPasswordService.validateEmail$.subscribe((res) => {
        this.gotoQuestionPasswordPage(res);
      });
    return true;
  }
}
