import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';
@Injectable({
  providedIn: 'root'
})
export class UserAccessManagementService {
  userList: any;
  customerDetails = new BehaviorSubject({});
  updateCustDetails = new BehaviorSubject(false);
  resetPasswordDetails = new BehaviorSubject(false);
  changeHistory=new BehaviorSubject(false);

  constructor(private readonly baseService: BaseService,public excelService: ExcelDownloadService) { }

  getb2bUnitList(params): Observable<any> {
    return this.baseService.
      get(`${config.USER_ACCOUNT}asagent/assisted-service/user-management?fields=DEFAULT`, params);
  }

  getCustomerDetails(customerId, idNo) {
    this.baseService.get('/users/asagent/assisted-service/user-management/' + customerId + '?fields=FULL').subscribe((resp) => {
      if (resp) {
        this.customerDetails.next({ res: resp, id: idNo })
      }
    })

  }
  getChangeHistory(customerId) {
    this.baseService.get('/users/asagent/assisted-service/change-history/' + customerId + '?fields=FULL').subscribe((resp) => {
      if (resp) {
        this.changeHistory.next(resp)
      }
    })

  }
  
  updateCustomerDetails(customerId, params) {
    this.baseService.patch('/users/asagent/assisted-service/user-management/' + customerId + '?fields=FULL', params)
      .subscribe((res) => {
        this.updateCustDetails.next(res)
      }, (err) => { this.updateCustDetails.next(err); }
      )
  }

  resetPasswordService(customerId, params) {
    this.baseService.post('/users/asagent/assisted-service/reset-password/' + customerId + '/?fields=FULL', params)
      .subscribe((res) => {
        this.resetPasswordDetails.next(res)
      }, (err) => { this.resetPasswordDetails.next(err); }
      )
  }
  public downloadXls(data, name) {
    this.excelService.exportAsExcelFile(data, name, true);
  }
}
