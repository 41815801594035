import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  cmsResponse: any;
  tandc: any;
  @Input() compName;

  constructor(private readonly cmsData: CmsService) { }


  ngOnInit(): void {
    this.cmsData.getComponentData(this.compName).subscribe((res) => {
      this.cmsResponse = res;
    }
    );
    if (this.cmsResponse && this.cmsResponse.content) {
      this.tandc = this.cmsResponse.content;
    }
  }

}
