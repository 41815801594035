<div [ngClass]="{
    'square-banner':
      bannerComponent === 'AccountRegistrationConfirmationBannerComponent',
    'banner-Inf': bannerComponent === 'SanofiAccountOverviewBannerComponent6'
  }">
  <a *ngIf = "!!cmsResponse && !!imageUrl" href="unsafe:javascript:void(0)" rel="{{redirectionLink}}" [ngClass] = "{'disable-Link' : !redirectionLink}" type="{{type}}" class="app-navigation-links">
    <section>
    <img src="{{ imageUrl }}" [ngClass]="{'banner': bannerComponent === 'OrderHistoryBannerComponent',
      'invoice-banner': bannerComponent === 'InvoiceBannerComponent',
      'influenza-Banner': bannerComponent === 'SanofiFluDashboardBannerComponent'}" alt="order" />
    </section>
  </a>
</div>