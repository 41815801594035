import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  GlobalMessageService,
  I18nModule,
  UrlModule,
  WindowRef,
} from '@spartacus/core';
import {
  FormErrorsModule,
  OutletPosition,
  provideOutlet,
} from '@spartacus/storefront';
import { LoginFormModule } from '@spartacus/user/account/components';
import {
  VscaLoginFormComponent,
  VscaLoginFormComponentService,
} from 'src/app/spartacus/features/user/account/components/login-form';
import { VscaAuthService } from 'src/core/auth/user-auth/facade/vsca-auth.service';
import { RouterModule } from '@angular/router';

import { LocationStripComponent } from 'src/app/shared/modules/location-strip/location-strip.component';
import { LocationStripModule } from 'src/app/shared/modules/location-strip/location-strip.module';
import { HomeScreenComponent } from './home-screen.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [HomeScreenComponent, LoginComponent],
  imports: [
    CommonModule,
    LoginFormModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    LocationStripModule,
    RouterModule,
  ],

  providers: [
    provideOutlet({
      id: 'Section2B',
      position: OutletPosition.AFTER,
      component: VscaLoginFormComponent,
    }),
    {
      provide: VscaLoginFormComponentService,
      useClass: VscaLoginFormComponentService,
      deps: [VscaAuthService, GlobalMessageService, WindowRef],
    },
    provideOutlet({
      id: 'BottomHeaderSlot',
      position: OutletPosition.AFTER,
      component: LocationStripComponent,
    }),
  ],
})
export class HomeScreenModule {}
