<div class="row">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <form [formGroup]="passwordAnswerForm">
      <div class="col-md-12">
        <h2 class="mb-4 c-form-title">
          {{ "forgotUserPassword.confirmIdentity" | cxTranslate }}
        </h2>
      </div>
      <div class="col-md-8 col-lg-5">
        <div class="c-text-input">
          <label class="c-form-label">{{
            userDataRecoveryQuestion?.passwordQuestion
          }}</label>
          <input
            type="text"
            name="answer"
            id="answer"
            formControlName="answer"
            (ngModelChange)="validateAnswer()"
          />
          <cx-form-errors
            class="text-danger"
            *ngIf="
              (passwordAnswerForm.get('answer').hasError('required') &&
                passwordAnswerForm.get('answer').touched) ||
              answerRequired
            "
          >
            <p>
              {{ "forgotUserPassword.requiredField" | cxTranslate }}
            </p>
          </cx-form-errors>
          <div class="mt-4">
            <button
              type="submit"
              class="c-button c-button--primary"
              (click)="onSubmit()"
            >
              {{ "forgotUserPassword.submit" | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
