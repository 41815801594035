import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';
import { MultiFactorAuthService } from '../../services/multi-factor-auth.service';
import { OtpModalComponent } from '../otp-modal/otp-modal/otp-modal.component';
import { AddCardComponent } from './add-card.component';
@Injectable({
  providedIn: 'root',
})
export class AddCardService {
  public cardDetails = new BehaviorSubject<any>(false);
  public cardAdded = new BehaviorSubject<any>(false);
  public cardCloseError = new Subject<any>();

  isMfaPopupLoading = false;

  constructor(private readonly baseService: BaseService, protected mfaService: MultiFactorAuthService, protected modalService: NgbModal) {}

  public addCard(cardToken) {
    return this.baseService.post(config.ADD_CARD, cardToken);
  }

  addCardWithMFA() {
    if(this.isMfaPopupLoading) return;
    this.isMfaPopupLoading = true;
    this.mfaService.requestNewOTP().pipe(take(1)).subscribe(res => {
      this.isMfaPopupLoading = false;
      if (!res[`limited`]) {
        const modalRef = this.modalService.open(OtpModalComponent);
        modalRef.componentInstance.mfa = res;
        modalRef.result.then(res => {
          if(res == 'success') {
            const modRef = this.modalService.open(AddCardComponent);
            modRef.componentInstance.isCheckbox = false;
          }
        })
      } else {
        const modalRef = this.modalService.open(OtpModalComponent);
        modalRef.componentInstance.isLimitReached = true;
      }
    })
  }
}
