<!-- Credits -->
<section *ngIf="creditList.length > 0" class="mx-3 mx-lg-0 my-4">
  <!-- Heading -->
  <section class="row">
    <section class="col-md-12 col-xs-12 op_ovice">
      <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
        {{ 'makeAPayment.credits_title' | cxTranslate }}
      </h2>
    </section>
  </section>
  <!-- Table Desktop-->
  <section class="row">
    <section class="col-md-12">
      <table
        aria-describedby="creditsTable"
        id="applyCreditsTable"
        class="credits-table c-table c-table--branded"
      >
        <thead>
          <tr>
            <th scope="col"></th>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'docNo', creditList, 'normal')"
            >
              {{ 'makeAPayment.credit#' | cxTranslate }}
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'blineDate', creditList, 'Date')"
            >
              {{ 'makeAPayment.credit_date' | cxTranslate }}
              <i class="my-icon fas fa-caret-up"></i>
            </th>
            <th
              scope="col"
              class="text-align col-width"
              (click)="sortColumn($event, 'amount', creditList, 'Number')"
            >
              {{ 'makeAPayment.credit_amount' | cxTranslate }}
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th scope="col" class="col-width dropdown-width">
              {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}
            </th>
            <th scope="col" class="text-align col-width">
              {{ 'makeAPayment.credit_applied' | cxTranslate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of creditList; let i = index">
            <td class="payment-chk-mob">
              <section class="mt-3">
                <input
                  id="check{{ i }}"
                  type="checkbox"
                  name="check"
                  (change)="
                    parsePaymentType(
                      data,
                      $event.target.checked,
                      creditItems,
                      i,
                      true
                    )
                  "
                  [(ngModel)]="data.checked"
                />
                <label for="check{{ i }}"> </label>
              </section>
            </td>
            <td>
              <span class="font-bold d-md-none">
                {{ 'makeAPayment.credit#' | cxTranslate }}:
              </span>
              <a
                *ngIf="
                  data.docType === 'RV' ||
                  data.docType === 'RD' ||
                  data?.docType === 'RF'
                "
                href="javascript: void(0);"
                class="underline"
                (click)="creditPdf(data.refDocNo)"
              >
                {{ data.refDocNo }}
              </a>

              <a
                *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
                href="javascript: void(0);"
                class="underline"
                (click)="creditPdf(data.allocNmbr)"
              >
                {{ data.allocNmbr }}
              </a>
              <span
                *ngIf="
                  data.docType === 'DR' ||
                  data.docType === 'DG' ||
                  data?.docType === 'DY'
                "
              >
                {{ data.refDocNo }}
              </span>
              <span
                *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
              >
                {{ data.dochdrtxt }}
              </span>
              <span *ngIf="data.docType === 'YR'">
                {{ data.refhdr }}
              </span>
            </td>
            <td>
              <span class="font-bold d-md-none"
                >{{ 'makeAPayment.credit_date' | cxTranslate }}:</span
              >
              {{ data.blineDate }}
            </td>
            <td class="amount-align">
              <span class="font-bold d-md-none">
                {{ 'makeAPayment.credit_amount' | cxTranslate }}:
              </span>
              ({{ data.amount | currency }})
            </td>
            <td
              class="sort-by-region fst-dropdown custom-selectpay pad-btm cred-dropdown"
            >
              <div class="font-bold d-md-none mb-2">
                {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}:
              </div>
              <ng-select
                [items]="data.creditDropDown"
                (change)="updateInvoiceSource($event, i)"
                [searchable]="false"
                [clearable]="false"
                bindLabel="displayValue"
                bindValue="refDocNo"
                placeholder="{{
                  'userRegistration.selectPlaceHolder' | cxTranslate
                }}"
                [(ngModel)]="data.selectedInvoice"
                class="dropdown-credit pull-left"
                [disabled]="!data.checked"
              >
              </ng-select>

              <span
                class="amount-value-error credits"
                [ngClass]="
                  data.checked &&
                  data.errorMsg &&
                  data.checkedAndNotFilledInvoice &&
                  clicked
                    ? 'visible-ui'
                    : 'invisible-ui'
                "
                >{{ 'makeAPayment.credits_invoice_select_msg' | cxTranslate }}
              </span>
            </td>

            <td class="text-right">
              <span class="font-bold d-md-none">
                {{ 'makeAPayment.credit_applied' | cxTranslate }}: </span
              >{{ data?.creditsApplied | currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </section>
  <!-- Button -->
  <section class="row">
    <div class="col-md-12">
      <button
        [ngClass]="
          creditItems.length != 0 && !creditItems[0].errorMsg ? '' : 'display'
        "
        type="button"
        [disabled]="!isValid"
        class="c-button c-button--primary pull-right btnpaymentinvoicecredit"
        (click)="initiateCreditPaymentFlow()"
      >
        {{ 'makeAPayment.continue_button_txt' | cxTranslate }}
      </button>
    </div>
  </section>
</section>
<!-- No Credit -->
<section
  class="credits-section row mx-3 mx-lg-0"
  *ngIf="openCreditInvoice?.length === 0 || creditList?.length === 0"
>
  <div class="col-md-12">
    <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
      {{ 'makeAPayment.credits_title' | cxTranslate }}
    </h2>
    <h3 class="font-bold">
      {{ 'makeAPayment.no_open_credits' | cxTranslate }}
    </h3>
  </div>
</section>

<!-- Invoices -->
<div class="row mt-4 mx-3 mx-lg-0" *ngIf="paymentList.length > 0">
  <div class="col-md-12">
    <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
      {{ 'makeAPayment.invoice_title' | cxTranslate }}
    </h2>
    <p class="mb-0">
      {{ 'makeAPayment.card_accept_msg' | cxTranslate }}
      <img src="assets/icons/mastercard.png" alt="amex" />
      <img src="assets/icons/visa.png" alt="visa" />
    </p>
  </div>
  <div class="col-md-12">
    <table
      aria-describedby="invoicesTable"
      id="applyCreditsTable"
      class="payments-table c-table c-table--branded"
    >
      <thead>
        <tr>
          <th scope="col" class="col-width">
            {{ 'makeAPayment.payment_option_open_inv' | cxTranslate }}
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
          >
            {{ 'makeAPayment.invoice#' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.invoice_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.due_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-up"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'amount', paymentList, 'Number')"
          >
            {{ 'makeAPayment.invoice_balance' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th scope="col" class="col-width last-invoice-th">
            {{ 'makeAPayment.payment_amount' | cxTranslate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of paymentList; let i = index">
          <td class="pay checkbox-pay d-flex pt-4">
            <div class="mt-4">
              <input
                id="check-invoice{{ i }}"
                type="checkbox"
                class="payment-checkbox"
                (change)="
                  parsePaymentType(data, $event.target.checked, invoiceItems, i)
                "
                [(ngModel)]="data.checked"
              />
              <label for="check-invoice{{ i }}"> </label>
            </div>
            <span class="mt-2">{{ paymentOptionInvoicesDefault }}</span>
          </td>
          <td class="pay">
            <span class="font-bold d-md-none"
              >{{ 'makeAPayment.invoice#' | cxTranslate }}:
            </span>
            <a
              *ngIf="
                data.docType === 'RV' ||
                data.docType === 'RD' ||
                data?.docType === 'RF'
              "
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.refDocNo)"
            >
              {{ data.refDocNo }}
            </a>

            <a
              *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.allocNmbr)"
            >
              {{ data.allocNmbr }}
            </a>
            <span
              *ngIf="
                data.docType === 'DR' ||
                data.docType === 'DG' ||
                data?.docType === 'DY'
              "
            >
              {{ data.refDocNo }}
            </span>
            <a
              *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.dochdrtxt)"
            >
              {{ data.dochdrtxt }}
            </a>
            <span *ngIf="data.docType === 'YR'">
              {{ data.refhdr }}
            </span>
          </td>
          <td class="pay">
            <span class="font-bold d-md-none"
              >{{ 'makeAPayment.invoice_date' | cxTranslate }}: </span
            >{{ data.blineDate }}
          </td>
          <td class="pay">
            <span class="font-bold d-md-none"
              >{{ 'makeAPayment.due_date' | cxTranslate }}:
            </span>
            <span
              [ngClass]="
                !!data.dueDate && dateComparison(data.dueDate) ? 'red' : ''
              "
              >{{ data.dueDate }}
              <ng-template #popSaveContent>
                <span class="popover-content">{{
                  'makeAPayment.past_due_date' | cxTranslate
                }}</span>
              </ng-template>
              <span
                [ngbPopover]="popSaveContent"
                triggers="manual"
                #p1="ngbPopover"
                placement="top"
                (click)="toggle(p1)"
                container="body"
                popoverClass="popover-black popover-links-invoice"
                *ngIf="!!data.dueDate && dateComparison(data.dueDate)"
              >
                <img
                  src="assets/icons/warning_red.png"
                  alt="warningIcon"
                  class="warningIconEvent"
                />
              </span>
            </span>
          </td>
          <td class="pay">
            <span class="font-bold d-md-none">
              {{ 'makeAPayment.invoice_balance' | cxTranslate }}:
            </span>
            {{ data.amount | currency }}
          </td>
          <td class="pay c-text-input">
            <div class="font-bold d-md-none">
              {{ 'makeAPayment.payment_amount' | cxTranslate }}:
            </div>
            <input
              type="text"
              [disabled]="!data.checked"
              [(ngModel)]="data.paymentAmount"
              (ngModelChange)="onInvoiceValueChange($event, i)"
              (focus)="onFocus(data.paymentAmount, i)"
              (blur)="onBlur(data.paymentAmount, i, data.amount)"
              class="invoice-input"
            />
            <div
              class="invoice-error"
              *ngIf="data.checked && data.invalidAmount"
            >
              {{ 'makeAPayment.invalid_amt' | cxTranslate }}
            </div>
            <div class="invoice-error" *ngIf="errorMessageStatus[i]">
              {{ 'makeAPayment.value_greater_than_zero' | cxTranslate }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row mx-3 mx-lg-0">
  <div class="col-md-12">
    <button
      [routerLink]="['/', 'openInvoicePayment']"
      [ngClass]="invoiceItems.length != 0 ? '' : 'display'"
      type="button"
      class="c-button c-button--primary pull-right btnpaymentinvoicecredit"
      (click)="initiateInvoicePaymentFlow()"
    >
      {{ 'makeAPayment.continue_button_txt' | cxTranslate }}
    </button>
  </div>
</div>
<!-- No Invoices -->
<section
  class="row credits-section mx-3 mx-lg-0 mb-4"
  *ngIf="openCreditInvoice?.length === 0 || paymentList?.length === 0"
>
  <div class="col-md-12">
    <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
      {{ 'makeAPayment.invoice_title' | cxTranslate }}
    </h2>
    <h3 class="font-bold">
      {{ 'makeAPayment.no_open_invoices' | cxTranslate }}
    </h3>
  </div>
</section>
<div class="mb-4 pb-4"></div>
