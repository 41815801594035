import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { BaseSiteService, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings
} from 'ng-recaptcha';
import { take } from 'rxjs/operators';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { NeedAssistanceModule } from '../shared/modules/need-assistance/need-assistance.module';
import { AccountConfirmationInformationComponent } from './account-registration/account-confirmation-information/account-confirmation-information.component';
import { AccountHeaderComponent } from './account-registration/account-header/account-header.component';
import { AccountRegistrationComponent } from './account-registration/account-registration.component';
import { BillingInformationComponent } from './account-registration/billing-information/billing-information.component';
import { ContactsInformationComponent } from './account-registration/contacts-information/contacts-information.component';
import { PhoneMaskDirective } from './account-registration/phone-mask.directive';
import { ShippingInformationComponent } from './account-registration/shipping-information/shipping-information.component';
import { AccountInformationComponent } from './user-registration/account-information/account-information.component';
import { UserConfirmationComponent } from './user-registration/user-confirmation/user-confirmation.component';
import { UserHeaderComponent } from './user-registration/user-header/user-header.component';
import { UserInformationComponent } from './user-registration/user-information/user-information.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { ZipMaskDirective } from './user-registration/zip-mask.directive';

const globalSettings: RecaptchaSettings = {
  siteKey: '',
};
const startIndex = 6;
const totalCharsNeeded = 2;
const language = location.pathname.substr(startIndex, totalCharsNeeded);


function loadRecaptcha(baseSiteService: BaseSiteService) {
  return () => baseSiteService.get().pipe(take(1)).subscribe((baseSite: any) => {
    globalSettings.siteKey = baseSite?.captchaConfig?.publicKey;
  })
}

@NgModule({
  declarations: [
    AccountRegistrationComponent,
    UserRegistrationComponent,
    BillingInformationComponent,
    ShippingInformationComponent,
    ContactsInformationComponent,
    AccountConfirmationInformationComponent,
    AccountHeaderComponent,
    PhoneMaskDirective,
    AccountInformationComponent,
    UserInformationComponent,
    UserHeaderComponent,
    UserConfirmationComponent,
    ZipMaskDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NeedAssistanceModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FluDashboardBannerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RegistrationPageComponent: {
          component: AccountRegistrationComponent,
        },
        CustomerRegistrationPageComponent: {
          component: UserRegistrationComponent,
        },
        UserRegistrationPageComponent: {
          component: UserConfirmationComponent,
        },
        AccountRegistrationPageComponent: {
          component: AccountConfirmationInformationComponent,
        },
      },
      i18n: {
        backend: { loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json' },
      },
      chunks: translationChunksConfig,
    } as CmsConfig),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [BaseSiteService],
      useFactory: loadRecaptcha,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: language,
    },
  ],
  exports: [PhoneMaskDirective, ZipMaskDirective],
})
export class CustomUserRegistrationModule {}
