<div class="Registration-nav col-xs-12 col-sm-12 col-md-12 p0 multipleHeader">
  <div class="header-item payment">
    <div class="Common-registration" [ngClass]="selectedHeader=='accountInformation'?'Common-active':''">
      <span>1</span>
      <span>{{ "userRegistration.accountInformation.header" | cxTranslate }}</span>
    </div>
  </div>
  <div class="header-item">
    <div class="Common-registration" [ngClass]="selectedHeader=='userInformation'?'Common-active':''">
      <span>2</span>
      <span>{{ "userRegistration.userInformation.header" | cxTranslate }}</span>
    </div>
  </div>
  <div class="header-item confirmation">
    <div class="Common-registration" [ngClass]="selectedHeader=='confirmation'?'Common-active':''">
      <span>3</span>
      <span>{{ "userRegistration.userConfirmation.header" | cxTranslate }}</span>
    </div>
  </div>
</div>
<div *ngIf="selectedHeader=='userInformation'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
    <div class="Common-registration" [ngClass]="selectedHeader=='userInformation'?'Common-active':''">
      <span>2</span>
      <span>{{ "userRegistration.userInformation.header" | cxTranslate }}</span>
    </div>
  </div>
</div>
<div *ngIf="selectedHeader=='accountInformation'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 payment">
    <div class="Common-registration" [ngClass]="selectedHeader=='accountInformation'?'Common-active':''">
      <span>1</span>
      <span>{{ "userRegistration.accountInformation.header" | cxTranslate }}</span>
    </div>
  </div>
</div>
<div *ngIf="selectedHeader=='confirmation'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
    <div class="Common-registration" [ngClass]="selectedHeader=='confirmation'?'Common-active':''">
      <span>3</span>
      <span>{{ "userRegistration.userConfirmation.header" | cxTranslate }}</span>
    </div>
  </div>
</div>