import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { translationChunksConfig } from '@spartacus/assets';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NeedAssistanceModule } from '../shared/modules/need-assistance/need-assistance.module';
import { ForgotUserPasswordComponent } from './forgot-user-password/forgot-user-password.component';
import { HelpHintRecoveryQuestionComponent } from './help-hint-recovery-question/help-hint-recovery-question.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UserDataRecoveryActionComponent } from './user-data-recovery-action/user-data-recovery-action.component';

@NgModule({
  declarations: [
    ForgotUserPasswordComponent,
    SetPasswordComponent,
    HelpHintRecoveryQuestionComponent,
    UserDataRecoveryActionComponent,
    LoginPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    NeedAssistanceModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        LoginHelpComponent: {
          component: ForgotUserPasswordComponent,
        },
        UserNameRecoverySuccessComponent: {
          component: UserDataRecoveryActionComponent,
        },
        HintQuestionAnswerComponent: {
          component: HelpHintRecoveryQuestionComponent,
        },
        ForgotPasswordComponent: {
          component: SetPasswordComponent,
        },
        LoginPageReferenceComponent: {
          component: LoginPageComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class ForgotUserPasswordModule {}
