import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, provideConfig } from '@spartacus/core';
import { translationChunksConfig } from '@spartacus/assets';
import { ExternalsiteAlertComponent } from './externalsite-alert/externalsite-alert.component';



@NgModule({
  declarations: [ExternalsiteAlertComponent],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [ExternalsiteAlertComponent],  
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ]
})
export class ExternalsiteAlertModule { }
