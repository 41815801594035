import { Component, HostListener } from '@angular/core';
import { TranslationService} from '@spartacus/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
})
export class BackToTopComponent {
  isShow: boolean = false;
  topPosToStartShowing = 100;

  constructor(private readonly translation: TranslationService) { }
  
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
