<div class="checkout-modal">
  <div class="close" (click)="close()">
    <i
      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
    ></i>
  </div>
  <div class="modal-header relative">
    <h2 class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100">
      {{ 'addToCartItems.prodDetails' | cxTranslate }}
    </h2>
  </div>
  <!-- Modal body -->
  <div class="modal-body miniCart-popover">
    <div class="row product-details pb-3" id="leftCont-rightNav">
      <!-- Left -->
      <section class="col-sm-8 pb-2">
        <h1
          class="c-heading c-heading-h2 mb-3"
          [innerHTML]="productDetails?.name"
        ></h1>
        <p
          class="text-fixed-medium"
          [innerHTML]="productDetails?.description"
        ></p>
        <section class="text-fixed-medium">
          <p *ngIf="productDetails?.batchInfo?.batchNumber" class="mb-2">
            <b>{{ 'plp.batchNumber' | cxTranslate }}: </b>
            <span>{{ productDetails?.batchInfo?.batchNumber }}</span>
          </p>
          <p *ngIf="productDetails?.batchInfo?.batchNumber" class="mb-2">
            <b>{{ 'plp.expiryDate' | cxTranslate }}: </b>
            <span>{{ productDetails?.batchInfo?.expiryDate }}</span>
          </p>
          <p *ngIf="productDetails?.batchInfo?.batchNumber" class="mb-2">
            {{ 'plp.batchInfo' | cxTranslate }}
          </p>
          <p class="mb-2">
            <b>{{ 'plp.productNumber' | cxTranslate }}: </b>
            <span class="desc-details">{{ productDetails?.code }}</span>
          </p>
          <p *ngIf="productDetails?.actualPotency" class="mb-2">
            <b>{{ 'plp.actualPotency' | cxTranslate }}: </b>
            <span class="desc-details">{{
              productDetails?.actualPotency
            }}</span>
          </p>
          <hr class="line" />
          <section
            class="sap-allocation-area"
            *ngIf="
              !productDetails?.discontinued &&
              productDetails?.available &&
              !productDetails?.flu
            "
          >
            <section *ngIf="productDetails?.sapAllocation === true">
              <p class="text-danger">
                <b>{{ 'plp.productAllocation' | cxTranslate }}</b>
              </p>
              <p>
                {{ 'plp.allocationDetails' | cxTranslate }}
              </p>
            </section>
          </section>
          <section class="third-col">
            <section class="row unit-calc">
              <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                <h5 class="mb-2 font-weight-bold color-grey-dark">
                  {{ 'selectVaccines.yourPrice' | cxTranslate }}
                </h5>
                <p class="c-heading c-heading-h3">
                  <span
                    *ngIf="
                      productDetails?.price == undefined ||
                      productDetails?.price?.value === undefined
                    "
                    >-</span
                  >
                  <span
                    *ngIf="
                      productDetails?.price &&
                      productDetails?.price?.value !== undefined
                    "
                  >
                    <span>{{ productDetails?.price?.value | currency }}</span
                    >{{ productDetails?.price?.currencyIso }}
                  </span>
                </p>
              </section>

              <section
                class="bottom-spacing availability-text"
                *ngIf="
                  !productDetails?.discontinued &&
                  !productDetails?.sapAllocation &&
                  !productDetails?.flu
                "
              ></section>
            </section>

            <section
              *ngIf="!productDetails?.discontinued && productDetails?.available"
              class="products-accordion clear-fix pt2"
            >
              <section class="panel-group">
                <section
                  class="panel panel-default"
                  *ngIf="productDetails?.volumePrices?.length > 0"
                >
                  <section class="panel-heading panel-endborder">
                    <section class="first-level-acc">
                      <label
                        ><img
                          src="../../assets/icons/price-master.png"
                          class="accordion-img"
                          alt=""
                        />
                        {{ 'selectVaccines.volumePrice' | cxTranslate }}</label
                      >
                      <span
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#pdp-list1"
                        class="acc-plus-icon fa"
                        aria-expanded="panelVolumeExpanded"
                        (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="
                          panelVolumeExpanded ? 'fa-minus' : 'fa-plus collapsed'
                        "
                      ></span>
                    </section>
                  </section>
                  <section
                    id="pdp-list1"
                    class="panel-collapse collapse"
                    aria-expanded="panelVolumeExpanded"
                    (click)="panelVolumeExpanded = !panelVolumeExpanded"
                    [ngClass]="panelVolumeExpanded ? 'in' : ''"
                  >
                    <section class="panel-body">
                      <section class="discount-list" id="vol-price-pdp">
                        <table aria-describedby="pdpTable">
                          <thead>
                            <th id=""></th>
                          </thead>
                          <tbody
                            *ngFor="
                              let prices of productDetails?.volumePrices;
                              let i = index
                            "
                          >
                            <tr>
                              <td>
                                {{ prices?.minQuantity }} -
                                {{ prices?.maxQuantity }}
                                {{ 'plp.units' | cxTranslate }}:
                              </td>
                              <td>{{ prices?.value | currency }}</td>
                              <td>
                                <span *ngIf="i > 0" class="save-price"
                                  >{{ 'plp.save' | cxTranslate }}
                                  {{
                                    productDetails?.volumePrices[0].value -
                                      productDetails?.volumePrices[i].value
                                      | currency
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <!-- product updates-->
            <section>
              <section class="products-accordion clear-fix pt2">
                <section class="panel-group">
                  <!-- update2 -->
                  <section
                    class="panel panel-default"
                    *ngIf="productDetails?.productUpdates"
                  >
                    <section class="panel-heading mt-4">
                      <section class="first-level-acc">
                        <label
                          ><img
                            src="../../assets/icons/info.png "
                            class="accordion-img mr-2"
                            alt=" "
                          />{{ 'plp.productUpdate' | cxTranslate }}
                        </label>
                        <span
                          data-toggle="collapse "
                          data-parent="#accordion "
                          href="#pdp-list3 "
                          class="acc-plus-icon fa"
                          aria-expanded="panelProductExpanded "
                          (click)="panelProductExpanded = !panelProductExpanded"
                          [ngClass]="
                            panelProductExpanded
                              ? 'fa-minus'
                              : 'fa-plus collapsed'
                          "
                        ></span>
                      </section>
                    </section>
                    <section
                      id="pdp-list3 "
                      class="panel-collapse collapse"
                      aria-expanded="panelProductExpanded "
                      (click)="panelProductExpanded = !panelProductExpanded"
                      [ngClass]="panelProductExpanded ? 'in' : ''"
                    >
                      <section class="panel-body productupdate-p">
                        {{ productDetails?.productUpdates }}
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <!--product updates end-->
          </section>
        </section>
      </section>
      <!-- Right -->
      <aside aria-label="Product Details" id="prodImg" class="col-sm-4">
        <a *ngIf="productDetails?.images && !productDetails?.images?.PRIMARY">
          <img
            class="image-prod"
            src="{{ baseUrl }}{{ productDetails.images[0]?.url }}"
            alt="prodImg"
          />
        </a>
        <a *ngIf="productDetails?.images?.PRIMARY">
          <img
            class="image-prod"
            src="{{ productDetails?.images?.PRIMARY?.product?.url }}"
            alt="prodImg"
          />
        </a>
        <a
          *ngIf="
            productDetails?.images === undefined ||
            productDetails?.images?.length === 0
          "
        >
          <img class="image-prod" [src]="fallbackImage" alt="defImg" />
        </a>
        <!-- Resources -->
        <section
          *ngIf="monographLink"
          class="prod-detail-pdfs u-border-top-grey pt-4"
        >
          <h3 class="font-bold mb-4">
            {{ 'plp.resources' | cxTranslate }}
          </h3>
          <p class="pdfs-list">
            <img
              class="pdf-icon mr-2"
              src=" ../../assets/icons/pdf.png "
              alt=" "
            />
            <a
              alt=" "
              [href]="monographLink"
              target=" _blank "
              class="c-link text-wrap"
              >{{ 'plp.monograph' | cxTranslate }}</a
            >
          </p>
        </section>
      </aside>
    </div>
  </div>
</div>
