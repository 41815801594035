import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomRegistrationService } from '../../custom-registration.service';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss'],
})
export class UserConfirmationComponent implements OnInit {
  message: any;
  userData: any = null;
  creditInvoice: string;
  compName = 'UserRegistrationBannerComponent';

  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.creditInvoice = 'invoiceTable';
    this.userData = JSON.parse(localStorage.getItem('userConfirmationData'));
    this.message = this.userData?.msgData;
    this.cdr.detectChanges();
  }
}
