<section class="row">
  <div class="col-md-12 pt-3 pb-4 mb-4">
    <div class="col-sm-8 mb-4 pb-1">
      <h2 class="mb-4 c-form-title">
        {{ "resetPassword.title" | cxTranslate }}
      </h2>
      <span class="security-Notify">{{
        "resetPassword.CondtionList" | cxTranslate
      }}</span>
      <ul class="required-ul">
        <li>{{ "resetPassword.lowercaseLetter" | cxTranslate }}</li>
        <li>{{ "resetPassword.uppercaseLetter" | cxTranslate }}</li>
        <li>{{ "resetPassword.number" | cxTranslate }}</li>
        <li>{{ "resetPassword.specialCharacters" | cxTranslate }}</li>
      </ul>
      <div class="error-message" *ngIf="invalidToken">
        {{ "resetPassword.invalidAccessToken" | cxTranslate }}
      </div>
    </div>
    <form [formGroup]="passwordForm">
      <div class="col-sm-8 col-lg-5">
        <div class="c-text-input mb-4">
          <label for="Password" class="c-form-label"
            >{{ "resetPassword.newPassword" | cxTranslate }}
          </label>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            (ngModelChange)="validatePassword()"
            formControlName="newPassword"
            autocomplete="off"
          />
          <cx-form-errors
            role="alert"
            aria-live="assertive"
            *ngIf="
              passwordIsNotValid &&
              !passwordForm.get('newPassword').hasError('required')
            "
            class="error-message"
            ><p>
              {{ "resetPassword.validPassword" | cxTranslate }}
            </p></cx-form-errors
          >
          <cx-form-errors
            role="alert"
            aria-live="assertive"
            *ngIf="
              (passwordForm.get('newPassword').hasError('required') &&
                passwordForm.get('newPassword').touched) ||
              (passwordRequired && !passwordIsNotValid)
            "
            class="error-message"
            ><p>
              {{ "forgotUserPassword.requiredField" | cxTranslate }}
            </p></cx-form-errors
          >
        </div>

        <div class="c-text-input mb-4">
          <label for="confirmPassword" class="c-form-label">{{
            "resetPassword.confirmNewPassword" | cxTranslate
          }}</label>
          <input
            name="checkPwd"
            type="password"
            formControlName="confirmPassword"
            autocomplete="off"
            (ngModelChange)="validateConfirmPassword()"
          />
          <cx-form-errors
            role="alert"
            aria-live="assertive"
            *ngIf="
              (passwordForm.get('confirmPassword').hasError('required') &&
                passwordForm.get('confirmPassword').touched) ||
              confirmPasswordRequired
            "
            class="error-message"
            ><p>
              {{ "forgotUserPassword.requiredField" | cxTranslate }}
            </p></cx-form-errors
          >
          <cx-form-errors
            role="alert"
            aria-live="assertive"
            *ngIf="
              !passwordForm.get('confirmPassword').hasError('required') &&
              passwordForm.get('newPassword').value !==
                passwordForm.get('confirmPassword').value
            "
            class="error-message"
            ><p>
              {{ "resetPassword.fieldsMismatchMsg" | cxTranslate }}
            </p></cx-form-errors
          >
        </div>

        <div class="mt-4">
          <button
            type="submit"
            class="c-button c-button--primary"
            (click)="onSubmit()"
          >
            {{ "forgotUserPassword.submit" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
    <br />
  </div>
</section>
