import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faChevronLeft,
  faFileExcel,
  faPrint,
  faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
import {
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { ExcelDownloadService } from '../../shared/services/excel-download.service';
import { OrderHistoryService } from '../order-history.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
})
export class OrderDetailsComponent implements OnInit {
  currentPopover: NgbPopover;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  orderDetailsData: any;
  userData: any;
  orderCode: any;
  baseUrl: string;
  saveCartName: string;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  modalReference: any;
  cartId: string;
  childCartID: any;
  standardCartDetails: any;
  errorMsg: string;
  cartItems: any;
  cartAllItems: any;
  fullTrackingUrl: any;
  userSubscription: Subscription;
  orderCartSubscription: Subscription;
  orderDetailSubscription: Subscription;
  addToCartSubscription: Subscription;
  userAccountSubscription: Subscription;
  userDetailSubscription: Subscription;
  saveOrderSubscription: Subscription;
  invalidOrderSubscription: Subscription;
  createCartSubscription: Subscription;
  addCartDetailsSubscription: Subscription;
  deleteOrderSubscription: Subscription;
  getb2bData: any;

  constructor(
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly orderService: OrderHistoryService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly router: Router,
    public excelService: ExcelDownloadService,
    private readonly config: NgbPopoverConfig,
    private readonly sanitizer: DomSanitizer
  ) {
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    const url = this.router.url;
    const orderId = url.match(/[^\/]+$/)[0];
    this.orderDetailsData = [];
    this.userData = [];
    this.cartAllItems = [];
    this.orderCode = this.route.snapshot.params.orderCode;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.userSubscription = this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
        this.orderService.getOrderDetails(this.userData.userName, orderId);
        if (this.userData && this.userData !== null) {
          this.orderService.getCarts(this.userData?.userName);
        }
      }
    });
    this.orderCartSubscription = this.orderService.orderCartItems$.subscribe(
      (res) => {
        if (res) {
          this.cartAllItems = res?.carts;
          this.cartAllItems = this.cartAllItems.filter(
            (item) => item?.saveTime === undefined
          );
          this.cartAllItems = this.cartAllItems[this.cartAllItems?.length - 1];
          this.cartId = this.cartAllItems?.code;
          if (this.cartId !== undefined) {
            this.cartChecking();
          } else {
            this.standardCartDetails = [];
          }
        }
      }
    );

    this.orderDetailSubscription = this.orderService.orderDetails$.subscribe(
      (res) => {
        if (res !== false && res !== null && res.length !== 0) {
          this.orderDetailsData = res;
          const createDate = this.orderDetailsData.created.split('T');
          this.orderDetailsData.created = createDate[0];
          // Duplication Removal
          this.orderDetailsData.shippingMethodArr = [];
          if (this.orderDetailsData?.consignments?.length > 0) {
            const value = new Set(
              this.orderDetailsData?.consignments?.map(
                (data) => data.shippingMethod
              )
            );
            this.orderDetailsData.shippingMethodArr = [...value];
          }

          this.orderDetailsData.entries.forEach((element) => {
            const imagesFormat = _.clone(
              _.findWhere(element.product?.images, { format: 'thumbnail' })
            );
            if (imagesFormat) {
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              element.PRIMARY = imagesFormat;
            }
            const data = this.orderDetailsData.consignments.filter(
              (item) =>
                element.product.code ===
                  item.entries[0]?.orderEntry?.product.code &&
                element.entryNumber === item.entries[0]?.orderEntry?.entryNumber
            );
            if (data.length !== 0) {
              element.prodConsignments = data;
              element?.prodConsignments?.forEach((consgData) => {
                let trackingidArray = consgData?.trackingID.split(',');
                consgData.trackingIdData = trackingidArray;
              });
            }
          });
          this.orderDetailsData.consignments.forEach((element) => {
            const statusDate = element.statusDate.split('T');
            element.statusDate = statusDate[0];
          });
          this.saveCartName = this.orderDetailsData?.shipToLoc_name?.slice(
            0,
            30
          );
          this.cd.detectChanges();
        }
      }
    );
  }
  navigateToTracking(data, url) {
    let a = url.split('<TRACKN>');
    this.fullTrackingUrl = a[0] + data;
  }
  cartChecking() {
    this.addToCart.getCarts(this.cartId, this.userData?.userName);
    this.addToCartSubscription = this.addToCart.cartItems$.subscribe(
      (cartData: any) => {
        if (cartData) {
          let stdCartDetails = [];
          stdCartDetails = cartData?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === this.orderDetailsData.cartType
            );
            if (stdCartDetails?.length !== 0) {
              this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            }
          }
        }
      }
    );
  }
  // getUserAccountData(userId): void {
  //   this.userDetailSubscription = this.addToCart.userAccountDetails$.subscribe(
  //     (userDataget) => {
  //       if (userDataget) {
  //         this.getb2bData = userDataget.res;
  //         this.getuserData = userDataget.res.shippingAddresses;
  //         this.searchedClientList = userDataget?.res?.shippingAddresses;
  //         this.selectedData = this.getuserData[0];
  //         this.saveCartName =
  //           this.orderDetailsData?.deliveryAddress?.companyName?.slice(0, 30);
  //         if (this.getuserData && this.getuserData?.length > 0) {
  //           this.selectedData = _.first(
  //             this.getuserData.filter(
  //               (item) => item.unitID === this.getb2bData?.uid
  //             )
  //           );
  //         }
  //         this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
  //         this.cd.detectChanges();
  //       }
  //     }
  //   );
  // }
  openProductDetails(productDetails) {
    if (productDetails.basePrice.formattedValue !== undefined) {
      productDetails.product.price = {
        value: productDetails.basePrice.formattedValue,
      };
    }
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = '';
    modalRef.componentInstance.unitSection = 'NoUnits';
  }
  openModal(content) {
    if (
      this.standardCartDetails !== undefined &&
      this.standardCartDetails?.length !== 0
    ) {
      this.modalReference = this.modalService.open(content, {
        size: 'md',
        windowClass: 'modal-md',
      });
    } else {
      this.getCartId('addCart');
    }
  }
  toggle(popover) {
    this.currentPopover = popover;
    if (popover.isOpen()) {
      popover.close();
      this.errorMsg = '';
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.orderDetailsData?.shipToLoc_name?.slice(0, 30);
  }
  existingCart() {
    this.modalReference.close();
  }
  excelDownload() {
    const excelArray = [];
    const excelName = 'Order Details';
    this.orderDetailsData.entries.forEach((element) => {
      const doses = element.quantity * element.product.quantityPerUnit;
      const obj = {
        'Product ID': element.product.code,
        'Qty (unit/doses)': `${element.quantity}/${doses}`,
        'Product Name': element.product.name,
        'Bill To Location': this.orderDetailsData.billingAddress.unitID,
        'Ship To Location': this.orderDetailsData.deliveryAddress.unitID,
      };
      excelArray.push(obj);
    });
    this.excelService.exportAsExcelFile(excelArray, excelName, true);
  }
  saveOrder() {
    let code;
    if (
      this.orderDetailsData?.guid !== undefined &&
      this.orderDetailsData?.guid !== ''
    ) {
      code = this.orderDetailsData?.guid;
    } else {
      code = this.orderDetailsData?.code;
    }
    var reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.orderService.saveCartOrderDetails(
        this.userData.userName,
        code,
        this.saveCartName
      );
      this.saveOrderSubscription =
        this.orderService.saveCartOrderSuccess$.subscribe((res) => {
          if (res) {
            this.currentPopover.close();
          }
        });
      this.invalidOrderSubscription =
        this.orderService.invalidOrderCartName$.subscribe((res) => {
          if (res) {
            this.errorMsg = _.pluck(res?.error?.errors, 'message');
            this.errorMsg =
              this.errorMsg === 'This field is required.'
                ? 'Cart name cannot be blank'
                : this.errorMsg;
          }
        });
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }
  getCartId(value) {
    if (this.cartAllItems === undefined) {
      this.orderService.createCart(this.userData?.userName);
      this.createCartSubscription =
        this.orderService.createdOrderCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.orderService.getCarts(this.userData?.userName);
            this.addToCarts(value);
          }
        });
    } else {
      if (this.cartId === undefined) {
        this.cartId = this.cartAllItems?.code;
      }
      this.addToCarts(value);
    }
  }
  addToCarts(value) {
    const data = [];
    this.orderDetailsData.entries.forEach((element) => {
      data.push({
        entryNumber: 0,
        productCode: element.product.code,
        quantity: element.quantity,
      });
    });
    const objArr = {
      addToCartForm: data,
    };
    this.orderService.addToCart(this.userData.userName, objArr, this.cartId);
    this.addCartDetailsSubscription =
      this.orderService.addCartDetails$.subscribe((res) => {
        if (res !== false && res !== null) {
          if (value === 'clearCart') {
            this.modalReference.close();
          }
          this.addToCart.getAllCarts(this.userData?.userName);
          this.router.navigate(['/cart']);
        }
      });
  }
  clearChildCart(): void {
    this.orderService.clearCart(
      this.standardCartDetails.code,
      this.userData.userName
    );
    this.deleteOrderSubscription =
      this.orderService.deleteOrderItems$.subscribe((res) => {
        if (res) {
          this.getCartId('clearCart');
          this.orderService.deleteOrderItems.next(false);
        }
      });
  }

  getProductName(name) {
    return name ? this.sanitizer.bypassSecurityTrustHtml(name) : '';
  }

  getDescription(description) {
    return description
      ? this.sanitizer.bypassSecurityTrustHtml(description)
      : '';
  }
  print() {
    window.print();
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.orderCartSubscription?.unsubscribe();
    this.orderDetailSubscription?.unsubscribe();
    this.addToCartSubscription?.unsubscribe();
    this.userAccountSubscription?.unsubscribe();
    this.userDetailSubscription?.unsubscribe();
    this.saveOrderSubscription?.unsubscribe();
    this.invalidOrderSubscription?.unsubscribe();
    this.createCartSubscription?.unsubscribe();
    this.addCartDetailsSubscription?.unsubscribe();
    this.deleteOrderSubscription?.unsubscribe();
    this.orderDetailsData = [];
    this.userData = [];
    this.cartAllItems = [];
  }

  protected readonly faFileExcel = faFileExcel;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faRectangleList = faRectangleList;
  protected readonly faPrint = faPrint;
}
