import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
})
export class SetPasswordComponent implements OnInit {
  passwordIsNotValid = false;
  passwordForm: FormGroup;
  invalidToken = false;
  passwordRequired = false;
  confirmPasswordRequired = false;
  resetPasswordSubscription: any;
  passwordSubscription: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly forgotUserPasswordService: ForgotUserPasswordService,
    private readonly route: RoutingService
  ) {}

  ngOnInit() {
    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
    this.validatePassword();
  }

  validatePassword() {
    if (!this.passwordForm.controls.newPassword.errors?.required) {
      this.passwordRequired = false;
    }
    const checkLowerCaseLetter = new RegExp('^(?=.*?[a-z]).{1,15}$');
    const checkUpperCaseLetter = new RegExp('^(?=.*?[A-Z]).{1,15}$');
    const checkNumberRegex = new RegExp('^(?=.*?[0-9]).{1,15}$');
    const allSymbolsCheckRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const specialCharCheckRegex = new RegExp('^(?=.*?[*$%!()&^]).{1,15}$');
    this.passwordSubscription = this.passwordForm.controls[
      'newPassword'
    ].valueChanges.subscribe((value) => {
      if (value?.length > 0) {
        let counter = 0;
        const a = checkLowerCaseLetter.test(value);
        const b = checkUpperCaseLetter.test(value);
        const c = checkNumberRegex.test(value);
        const d = specialCharCheckRegex.test(value);
        const minPasswordLength = 8;
        const maxPasswordLength = 15;
        const validPasswordCriteria = 3;

        if (a === true) {
          counter += 1;
        }
        if (b === true) {
          counter += 1;
        }
        if (c === true) {
          counter += 1;
        }
        if (allSymbolsCheckRegex.test(value)) {
          if (d === true) {
            counter += 1;
          } else {
            counter = 0;
          }
        }
        if (
          counter >= validPasswordCriteria &&
          value.length >= minPasswordLength &&
          value.length <= maxPasswordLength
        ) {
          this.passwordIsNotValid = false;
        } else {
          this.passwordIsNotValid = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.resetPasswordSubscription.unsubscribe();
    this.passwordSubscription.unsubscribe();
  }

  validateConfirmPassword() {
    if (!this.passwordForm.controls.confirmPassword.errors?.required) {
      this.confirmPasswordRequired = false;
    }
  }

  onSubmit() {
    if (this.passwordForm.controls.newPassword.errors?.required) {
      this.passwordRequired = true;
    }
    if (this.passwordForm.controls.confirmPassword.errors?.required) {
      this.confirmPasswordRequired = true;
    }
    const urlString = window.location.href;
    const paramString = urlString.split('?')[1];
    const urlCode: any = paramString.split('=')[1];
    if (
      this.passwordForm.invalid ||
      this.passwordIsNotValid ||
      this.passwordForm.value.newPassword !==
        this.passwordForm.value.confirmPassword
    ) {
      return;
    }
    const params: any = {
      code: urlCode,
      confirmPassword: this.passwordForm.value.newPassword,
      password: this.passwordForm.value.confirmPassword,
    };
    this.forgotUserPasswordService.resetPassword(params);
    this.resetPasswordSubscription =
      this.forgotUserPasswordService.reserPasswordResponse$.subscribe((res) => {
        if (res !== null) {
          if (res && res.resetPassword === true) {
            this.route.goByUrl('/login/postresetpassword');
          } else {
            this.invalidToken = true;
          }
        }
      });
  }
}
