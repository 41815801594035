<div class="col-xs-12 col-sm-12 col-md-12 Registration-split">
  <h3 class="pdp-title">
    {{ 'accountRegistration.contactsInformation.title' | cxTranslate }}
  </h3>
  <span class="title-sub-heading">{{
    'accountRegistration.title_sub_heading' | cxTranslate
  }}</span>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8 p0">
    <form
      [formGroup]="contactsForm"
      id="contactsAddressForm"
      (ngSubmit)="createAccount()"
    >
      <div id="registerBilling">
        <div formGroupName="primaryData">
          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
          >
            <label for="primaryFirstName" class="Fields-label"
              >{{
                'accountRegistration.contactsInformation.firstName_label'
                  | cxTranslate
              }}
            </label>
            <input
              id="primaryFirstName"
              name="firstName"
              data-valid="valid_facility"
              aria-labelledby="billingAddressForm.facilityName tooltipFacilityName"
              type="text"
              class="Fields-input"
              formControlName="firstName"
              data-di-id="#billingAddressForm.facilityName"
              maxlength="40"
            />

            <span
              *ngIf="
                !!contactsForm.get('primaryData.firstName').value &&
                !contactsForm.get('primaryData.firstName').valid &&
                contactsForm.get('primaryData.firstName').touched
              "
              class="errorMessage"
              >{{
                'accountRegistration.special_validation_msg' | cxTranslate
              }}</span
            >
            <span
              *ngIf="
                contactsForm.get('primaryData.firstName').errors?.required &&
                !contactsForm.get('primaryData.firstName').valid &&
                contactsForm.get('primaryData.firstName').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
              >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
            </span>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
          >
            <label for="primaryLastName" class="Fields-label"
              >{{
                'accountRegistration.contactsInformation.lastName_label'
                  | cxTranslate
              }}
            </label>
            <input
              id="primaryLasttName"
              name="lastName"
              data-valid="valid_facility"
              aria-labelledby="billingAddressForm.facilityName tooltipFacilityName"
              type="text"
              class="Fields-input"
              formControlName="lastName"
              data-di-id="#billingAddressForm.facilityName"
              maxlength="40"
            />

            <span
              *ngIf="
                !!contactsForm.get('primaryData.lastName').value &&
                !contactsForm.get('primaryData.lastName').valid &&
                contactsForm.get('primaryData.lastName').touched
              "
              class="errorMessage"
              >{{
                'accountRegistration.special_validation_msg' | cxTranslate
              }}</span
            >
            <span
              *ngIf="
                contactsForm.get('primaryData.lastName').errors?.required &&
                !contactsForm.get('primaryData.lastName').valid &&
                contactsForm.get('primaryData.lastName').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
              >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
            </span>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
          >
            <label
              for="primaryTitle"
              id="contactTitleLabel"
              class="Fields-label"
              >{{
                'accountRegistration.contactsInformation.title_label'
                  | cxTranslate
              }}</label
            >
            <div class="">
              <div class="">
                <ng-select
                  name="title"
                  [items]="contactTitle"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="name"
                  bindValue="code"
                  placeholder="{{
                    'userRegistration.selectPlaceHolder' | cxTranslate
                  }}"
                  formControlName="title"
                  class="dropdown-credit"
                ></ng-select>
              </div>
              <span
                *ngIf="
                  contactsForm.get('primaryData.title').errors?.required &&
                  !contactsForm.get('primaryData.title').valid &&
                  contactsForm.get('primaryData.title').touched
                "
                class="errorMessage drop-downs"
                role="alert"
                aria-live="assertive"
                >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
              </span>
            </div>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
          >
            <label for="primaryRole" id="roleLabel" class="Fields-label">{{
              'accountRegistration.contactsInformation.role_label' | cxTranslate
            }}</label>
            <div class="">
              <div class="">
                <ng-select
                  name="role"
                  [items]="contactRole"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="name"
                  bindValue="code"
                  placeholder="{{
                    'userRegistration.selectPlaceHolder' | cxTranslate
                  }}"
                  formControlName="role"
                  class="dropdown-credit"
                ></ng-select>
              </div>
              <span
                *ngIf="
                  contactsForm.get('primaryData.role').errors?.required &&
                  !contactsForm.get('primaryData.role').valid &&
                  contactsForm.get('primaryData.role').touched
                "
                class="errorMessage drop-downs"
                role="alert"
                aria-live="assertive"
                >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
              </span>
            </div>
          </div>

          <div class="container col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
            <label
              for="preferredContact"
              id="preferredContactLabel"
              class="Fields-label"
              >{{
                'accountRegistration.contactsInformation.preferred_contact_label'
                  | cxTranslate
              }}</label
            >
            <div class="" id="preferredContactEmail">
              <div
                class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
              >
                <input
                  type="radio"
                  id="preferredEmail"
                  name="preferredContactMethod"
                  class="custom-control-input"
                  [value]="preferredContactMethod[0]"
                  formControlName="preferredContactMethod"
                />
                <label
                  class="custom-control-label secloct"
                  for="preferredEmail"
                >
                  {{ 'accountRegistration.form.email.label' | cxTranslate }}
                </label>
                <div style="display: inline">
                  <input
                    id="primaryEmail"
                    name="email"
                    data-valid="valid_mail"
                    type="text"
                    class="Fields-input contact-email"
                    data-view="newemail"
                    formControlName="email"
                    data-di-id="primaryEmail"
                    maxlength="241"
                  />
                  <span
                    *ngIf="
                      !!contactsForm.get('primaryData.email').value &&
                      !contactsForm.get('primaryData.email').valid &&
                      contactsForm.get('primaryData.email').touched
                    "
                    class="errorMessage contacts"
                  >
                    {{
                      'accountRegistration.form.email.email_validation_msg'
                        | cxTranslate
                    }}
                  </span>
                  <span
                    *ngIf="
                      contactsForm.get('primaryData.email').errors?.required &&
                      !contactsForm.get('primaryData.email').valid &&
                      contactsForm.get('primaryData.email').touched
                    "
                    class="errorMessage contacts"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="" id="preferredContactFax">
              <div
                class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
              >
                <input
                  type="radio"
                  id="primaryFax"
                  name="preferredContactMethod"
                  class="custom-control-input"
                  [value]="preferredContactMethod[1]"
                  formControlName="preferredContactMethod"
                />
                <label class="custom-control-label secloct" for="primaryFax">
                  {{
                    'accountRegistration.contactsInformation.fax_label'
                      | cxTranslate
                  }}
                </label>
                <div style="display: inline">
                  <input
                    id="primaryFaxp"
                    name="fax"
                    data-valid="valid_fax"
                    placeholder="xxx-xxx-xxxx"
                    type="text"
                    class="Fields-input contact-email"
                    data-view="newfax"
                    formControlName="fax"
                    maxlength="12"
                    appPhoneMask
                    data-di-id="#contactInfoForm0.fax"
                  />
                  <span
                    *ngIf="
                      !!contactsForm.get('primaryData.fax').value &&
                      !contactsForm.get('primaryData.fax').valid &&
                      contactsForm.get('primaryData.fax').touched
                    "
                    class="errorMessage contacts"
                  >
                    {{
                      'accountRegistration.form.office_fax.fax_validation_msg'
                        | cxTranslate
                    }}
                  </span>
                  <span
                    *ngIf="
                      contactsForm.get('primaryData.fax').errors?.required &&
                      !contactsForm.get('primaryData.fax').valid &&
                      contactsForm.get('primaryData.fax').touched
                    "
                    class="errorMessage contacts"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
            <label for="primaryPhone" class="Fields-label"
              >{{ 'accountRegistration.form.phone.label' | cxTranslate
              }}<span>{{
                'accountRegistration.optional_tag' | cxTranslate
              }}</span></label
            >
            <input
              id="primaryPhone"
              name="phone"
              data-valid="valid_phone"
              placeholder="xxx-xxx-xxxx"
              type="text"
              maxlength="12"
              appPhoneMask
              class="Fields-input phone-no-mask field-optional"
              formControlName="phone"
              data-di-id="primaryPhone"
            />

            <span
              class="errorMessage"
              *ngIf="
                !!contactsForm.get('primaryData.phone').value &&
                !contactsForm.get('primaryData.phone').valid &&
                contactsForm.get('primaryData.phone').touched
              "
              >{{
                'accountRegistration.form.phone.ph_validation_msg' | cxTranslate
              }}</span
            >
          </div>
        </div>

        <!-- New Section starts-->

        <div class="col-xs-12 col-sm-12 col-md-12 p0 acc_bg par_data">
          <label class="field-check">
            <input
              id="pr-data"
              name="invoiceContact"
              type="checkbox"
              value="false"
              formControlName="invoiceContact"
              data-di-id="#pr-data"
            />
          </label>
          <span class="check-text"
            ><b>{{
              'accountRegistration.contactsInformation.invoice_address_label'
                | cxTranslate
            }}</b>
            {{ 'accountRegistration.optional_tag' | cxTranslate }}</span
          >
          <div
            formGroupName="invoiceNewContactData"
            *ngIf="contactsForm.get('invoiceContact').value"
          >
            <div>
              <label
                for="invoiceRecipientContact"
                id="invoiceRecipient"
                class="Fields-label"
                >{{
                  'accountRegistration.contactsInformation.recipient_label'
                    | cxTranslate
                }}</label
              >
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="primaryContact"
                  name="primaryContact2"
                  [value]="isPrimaryContact[0]"
                  formControlName="primaryContact2"
                />
                <label
                  class="custom-control-label radio_align"
                  for="primaryContact"
                  >{{
                    'accountRegistration.contactsInformation.primary_cont_label'
                      | cxTranslate
                  }}</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="newContact"
                  name="primaryContact2"
                  [value]="isPrimaryContact[1]"
                  formControlName="primaryContact2"
                />
                <label
                  class="custom-control-label radio_align"
                  for="newContact"
                  >{{
                    'accountRegistration.contactsInformation.new_cont_label'
                      | cxTranslate
                  }}</label
                >
              </div>
            </div>

            <div
              id="registerBilling"
              class="billing-section"
              *ngIf="
                !contactsForm.get('invoiceNewContactData.primaryContact2')
                  .value && contactsForm.get('invoiceContact').value
              "
            >
              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
              >
                <label for="primaryFirstName" class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.firstName_label'
                      | cxTranslate
                  }}
                </label>
                <input
                  id="primaryFirstName"
                  name="firstName"
                  data-valid="valid_facility"
                  aria-labelledby="billingForm.firstName tooltipFacilityName"
                  type="text"
                  class="Fields-input"
                  formControlName="firstName"
                  data-di-id="#billingForm.firstName"
                  maxlength="40"
                />
                <span
                  *ngIf="
                    !!contactsForm.get('invoiceNewContactData.firstName')
                      .value &&
                    !contactsForm.get('invoiceNewContactData.firstName')
                      .valid &&
                    contactsForm.get('invoiceNewContactData.firstName').touched
                  "
                  class="errorMessage"
                  >{{
                    'accountRegistration.special_validation_msg' | cxTranslate
                  }}</span
                >
                <span
                  *ngIf="
                    contactsForm.get('invoiceNewContactData.firstName').errors
                      ?.required &&
                    !contactsForm.get('invoiceNewContactData.firstName')
                      .valid &&
                    contactsForm.get('invoiceNewContactData.firstName').touched
                  "
                  class="defaultMessage"
                  role="alert"
                  aria-live="assertive"
                  >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                </span>
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
              >
                <label for="formLastName" class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.lastName_label'
                      | cxTranslate
                  }}
                </label>
                <input
                  id="formLastName"
                  name="lastName"
                  data-valid="valid_facility"
                  type="text"
                  class="Fields-input"
                  formControlName="lastName"
                  autocomplete="off"
                  data-di-id="#formLastName"
                  maxlength="40"
                />

                <span
                  *ngIf="
                    !!contactsForm.get('invoiceNewContactData.lastName')
                      .value &&
                    !contactsForm.get('invoiceNewContactData.lastName').valid &&
                    contactsForm.get('invoiceNewContactData.lastName').touched
                  "
                  class="errorMessage"
                  >{{
                    'accountRegistration.special_validation_msg' | cxTranslate
                  }}</span
                >
                <span
                  *ngIf="
                    contactsForm.get('invoiceNewContactData.lastName').errors
                      ?.required &&
                    !contactsForm.get('invoiceNewContactData.lastName').valid &&
                    contactsForm.get('invoiceNewContactData.lastName').touched
                  "
                  class="defaultMessage"
                  role="alert"
                  aria-live="assertive"
                  >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                </span>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
              >
                <label
                  for="formTitle"
                  id="formTitleLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.title_label'
                      | cxTranslate
                  }}</label
                >
                <div class="">
                  <div class="">
                    <ng-select
                      name="title"
                      [items]="contactTitle"
                      [searchable]="false"
                      [clearable]="false"
                      bindLabel="name"
                      bindValue="code"
                      placeholder="{{
                        'userRegistration.selectPlaceHolder' | cxTranslate
                      }}"
                      formControlName="title"
                      class="dropdown-credit"
                    ></ng-select>
                  </div>
                  <span
                    *ngIf="
                      contactsForm.get('invoiceNewContactData.title').errors
                        ?.required &&
                      !contactsForm.get('invoiceNewContactData.title').valid &&
                      contactsForm.get('invoiceNewContactData.title').touched
                    "
                    class="errorMessage drop-downs"
                    role="alert"
                    aria-live="assertive"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
              >
                <label
                  for="formUserRole"
                  id="formUserRoleLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.role_label'
                      | cxTranslate
                  }}</label
                >
                <div class="">
                  <div class="">
                    <ng-select
                      name="role"
                      [items]="contactRole"
                      [searchable]="false"
                      [clearable]="false"
                      bindLabel="name"
                      bindValue="code"
                      placeholder="{{
                        'userRegistration.selectPlaceHolder' | cxTranslate
                      }}"
                      formControlName="role"
                      class="dropdown-credit"
                    ></ng-select>
                  </div>
                  <span
                    *ngIf="
                      contactsForm.get('invoiceNewContactData.role').errors
                        ?.required &&
                      !contactsForm.get('invoiceNewContactData.role').valid &&
                      contactsForm.get('invoiceNewContactData.role').touched
                    "
                    class="errorMessage drop-downs"
                    role="alert"
                    aria-live="assertive"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>

              <div
                class="container col-xs-12 col-sm-12 col-md-12 p0 c-text-input"
              >
                <label
                  for="preferredContactInvoices"
                  id="preferredContactInvoicesLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.preferred_contact_label'
                      | cxTranslate
                  }}</label
                >
                <div class="" id="preferredContactEmailInvoices">
                  <label
                    for="contactInfoForm0.email"
                    class="Fields-label mt0 res_lab"
                  >
                    {{ 'accountRegistration.form.email.label' | cxTranslate }}
                  </label>
                  <div
                    class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
                  >
                    <input
                      type="radio"
                      id="preferredEmailInvoices"
                      name="preferredContactMethod2"
                      class="custom-control-input"
                      [value]="preferredContactMethod[0]"
                      formControlName="preferredContactMethod2"
                    />
                    <label
                      class="custom-control-label secloct"
                      for="preferredEmailInvoices"
                    >
                    </label>
                    <div style="display: inline">
                      <input
                        id="primaryEmailInvoice"
                        name="email"
                        data-valid="valid_mail"
                        type="text"
                        class="Fields-input contact-email"
                        data-view="newemail"
                        formControlName="email"
                        data-di-id="primaryEmailInvoice"
                        maxlength="40"
                      />
                      <span
                        *ngIf="
                          !!contactsForm.get('invoiceNewContactData.email')
                            .value &&
                          !contactsForm.get('invoiceNewContactData.email')
                            .valid &&
                          contactsForm.get('invoiceNewContactData.email')
                            .touched
                        "
                        class="errorMessage contacts"
                      >
                        {{
                          'accountRegistration.form.email.email_validation_msg'
                            | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          contactsForm.get('invoiceNewContactData.email').errors
                            ?.required &&
                          !contactsForm.get('invoiceNewContactData.email')
                            .valid &&
                          contactsForm.get('invoiceNewContactData.email')
                            .touched
                        "
                        class="errorMessage contacts"
                        >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="" id="preferredContactFaxInvoices">
                  <label
                    for="contactInfoForm0.fax"
                    class="Fields-label mt0 res_lab"
                  >
                    {{
                      'accountRegistration.contactsInformation.fax_label'
                        | cxTranslate
                    }}
                  </label>
                  <div
                    class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
                  >
                    <input
                      type="radio"
                      id="preferredFaxInvoices"
                      name="preferredContactMethod2"
                      class="custom-control-input"
                      [value]="preferredContactMethod[1]"
                      formControlName="preferredContactMethod2"
                    />
                    <label
                      class="custom-control-label secloct"
                      for="preferredFaxInvoices"
                    >
                    </label>
                    <div style="display: inline">
                      <input
                        id="preferredFaxInvoice"
                        name="fax"
                        data-valid="valid_fax"
                        placeholder="xxx-xxx-xxxx"
                        type="text"
                        class="Fields-input contact-email"
                        data-view="newfax"
                        formControlName="fax"
                        appPhoneMask
                        maxlength="12"
                        data-di-id="#contactInfoForm0.fax"
                      />
                      <span
                        *ngIf="
                          !!contactsForm.get('invoiceNewContactData.fax')
                            .value &&
                          !contactsForm.get('invoiceNewContactData.fax')
                            .valid &&
                          contactsForm.get('invoiceNewContactData.fax').touched
                        "
                        class="errorMessage contacts"
                      >
                        {{
                          'accountRegistration.form.office_fax.fax_validation_msg'
                            | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          contactsForm.get('invoiceNewContactData.fax').errors
                            ?.required &&
                          !contactsForm.get('invoiceNewContactData.fax')
                            .valid &&
                          contactsForm.get('invoiceNewContactData.fax').touched
                        "
                        class="errorMessage contacts"
                        >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
                <label for="invoicePhone" class="Fields-label"
                  >{{ 'accountRegistration.form.phone.label' | cxTranslate
                  }}<span>{{
                    'accountRegistration.optional_tag' | cxTranslate
                  }}</span></label
                >
                <input
                  id="invoicePhone"
                  name="phone"
                  data-valid="valid_phone"
                  placeholder="xxx-xxx-xxxx"
                  type="text"
                  maxlength="12"
                  appPhoneMask
                  class="Fields-input phone-no-mask field-optional"
                  formControlName="phone"
                  data-di-id="invoicePhone"
                />

                <span
                  class="errorMessage"
                  *ngIf="
                    !!contactsForm.get('invoiceNewContactData.phone').value &&
                    !contactsForm.get('invoiceNewContactData.phone').valid &&
                    contactsForm.get('invoiceNewContactData.phone').touched
                  "
                  >{{
                    'accountRegistration.form.phone.ph_validation_msg'
                      | cxTranslate
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- New Section ends-->

        <!-- Other New Section Starts -->
        <div class="col-xs-12 col-sm-12 col-md-12 p0 acc_bg acc_shipping">
          <label class="field-check">
            <input
              id="new-pr-data"
              name="shippingContact"
              type="checkbox"
              value="false"
              formControlName="shippingContact"
              data-di-id="#new-pr-data"
            />
          </label>
          <span class="check-text"
            ><b>{{
              'accountRegistration.contactsInformation.shipping_address_label'
                | cxTranslate
            }}</b>
            {{ 'accountRegistration.optional_tag' | cxTranslate }}</span
          >
          <div
            formGroupName="shippingNewContactData"
            *ngIf="contactsForm.get('shippingContact').value"
          >
            <div>
              <label
                for="shippingRecipientContact"
                id="shippingRecipient"
                class="Fields-label"
                >{{
                  'accountRegistration.contactsInformation.recipient_label'
                    | cxTranslate
                }}</label
              >
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="primaryContact2"
                  name="primaryContact3"
                  [value]="isPrimaryContact[0]"
                  formControlName="primaryContact3"
                />
                <label
                  class="custom-control-label radio_align"
                  for="primaryContact2"
                  >{{
                    'accountRegistration.contactsInformation.primary_cont_label'
                      | cxTranslate
                  }}</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="newContact2"
                  name="primaryContact3"
                  [value]="isPrimaryContact[1]"
                  formControlName="primaryContact3"
                />
                <label
                  class="custom-control-label radio_align"
                  for="newContact2"
                  >{{
                    'accountRegistration.contactsInformation.new_cont_label'
                      | cxTranslate
                  }}</label
                >
              </div>
            </div>
            <div
              id="registerBilling"
              class="billing-section"
              *ngIf="
                !contactsForm.get('shippingNewContactData.primaryContact3')
                  .value && contactsForm.get('shippingContact').value
              "
            >
              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
              >
                <label for="formFirstNameShipping" class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.firstName_label'
                      | cxTranslate
                  }}
                </label>
                <input
                  id="formFirstNameShipping"
                  name="firstName"
                  data-valid="valid_facility"
                  aria-labelledby="formFirstNameShipping tooltipFacilityName"
                  type="text"
                  class="Fields-input"
                  formControlName="firstName"
                  data-di-id="#formFirstNameShipping"
                  maxlength="40"
                />
                <span
                  *ngIf="
                    !!contactsForm.get('shippingNewContactData.firstName')
                      .value &&
                    !contactsForm.get('shippingNewContactData.firstName')
                      .valid &&
                    contactsForm.get('shippingNewContactData.firstName').touched
                  "
                  class="errorMessage"
                  >{{
                    'accountRegistration.special_validation_msg' | cxTranslate
                  }}</span
                >
                <span
                  *ngIf="
                    contactsForm.get('shippingNewContactData.firstName').errors
                      ?.required &&
                    !contactsForm.get('shippingNewContactData.firstName')
                      .valid &&
                    contactsForm.get('shippingNewContactData.firstName').touched
                  "
                  class="defaultMessage"
                  role="alert"
                  aria-live="assertive"
                  >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                </span>
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input doubleLineContainer"
              >
                <label for="formLastNameShipping" class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.lastName_label'
                      | cxTranslate
                  }}
                </label>
                <input
                  id="formLastNameShipping"
                  name="lastName"
                  data-valid="valid_facility"
                  type="text"
                  class="Fields-input"
                  formControlName="lastName"
                  autocomplete="off"
                  data-di-id="#formLastNameShipping"
                  maxlength="40"
                />

                <span
                  *ngIf="
                    !!contactsForm.get('shippingNewContactData.lastName')
                      .value &&
                    !contactsForm.get('shippingNewContactData.lastName')
                      .valid &&
                    contactsForm.get('shippingNewContactData.lastName').touched
                  "
                  class="errorMessage"
                  >{{
                    'accountRegistration.special_validation_msg' | cxTranslate
                  }}</span
                >
                <span
                  *ngIf="
                    contactsForm.get('shippingNewContactData.lastName').errors
                      ?.required &&
                    !contactsForm.get('shippingNewContactData.lastName')
                      .valid &&
                    contactsForm.get('shippingNewContactData.lastName').touched
                  "
                  class="defaultMessage"
                  role="alert"
                  aria-live="assertive"
                  >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                </span>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
              >
                <label
                  for="formTitleShip"
                  id="formTitleShipLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.title_label'
                      | cxTranslate
                  }}</label
                >
                <div class="">
                  <div class="">
                    <ng-select
                      name="title"
                      [items]="contactTitle"
                      [searchable]="false"
                      [clearable]="false"
                      bindLabel="name"
                      bindValue="code"
                      placeholder="{{
                        'userRegistration.selectPlaceHolder' | cxTranslate
                      }}"
                      formControlName="title"
                      class="dropdown-credit"
                    ></ng-select>
                  </div>
                  <span
                    *ngIf="
                      contactsForm.get('shippingNewContactData.title').errors
                        ?.required &&
                      !contactsForm.get('shippingNewContactData.title').valid &&
                      contactsForm.get('shippingNewContactData.title').touched
                    "
                    class="errorMessage drop-downs"
                    role="alert"
                    aria-live="assertive"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input asses_dropdown"
              >
                <label
                  for="formUserRoleShip"
                  id="formUserRoleShipLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.role_label'
                      | cxTranslate
                  }}</label
                >
                <div class="">
                  <div class="">
                    <ng-select
                      name="role"
                      [items]="contactRole"
                      [searchable]="false"
                      [clearable]="false"
                      bindLabel="name"
                      bindValue="code"
                      placeholder="{{
                        'userRegistration.selectPlaceHolder' | cxTranslate
                      }}"
                      formControlName="role"
                      class="dropdown-credit"
                    ></ng-select>
                  </div>
                  <span
                    *ngIf="
                      contactsForm.get('shippingNewContactData.role').errors
                        ?.required &&
                      !contactsForm.get('shippingNewContactData.role').valid &&
                      contactsForm.get('shippingNewContactData.role').touched
                    "
                    class="errorMessage drop-downs"
                    role="alert"
                    aria-live="assertive"
                    >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                  </span>
                </div>
              </div>

              <div
                class="container col-xs-12 col-sm-12 col-md-12 p0 c-text-input"
              >
                <label
                  for="preferredContactShippingg"
                  id="preferredContactShippinggLabel"
                  class="Fields-label"
                  >{{
                    'accountRegistration.contactsInformation.preferred_contact_label'
                      | cxTranslate
                  }}</label
                >
                <div class="" id="preferredContactEmailInvoices">
                  <label
                    for="preferredContactEmailShipping"
                    class="Fields-label mt0 res_lab"
                  >
                    {{ 'accountRegistration.form.email.label' | cxTranslate }}
                  </label>
                  <div
                    class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
                  >
                    <input
                      type="radio"
                      id="preferredEmailShipping"
                      name="preferredContactMethod3"
                      class="custom-control-input"
                      [value]="preferredContactMethod[0]"
                      formControlName="preferredContactMethod3"
                    />
                    <label
                      class="custom-control-label secloct"
                      for="preferredEmailShipping"
                    >
                    </label>
                    <div style="display: inline">
                      <input
                        id="primaryEmailShipp"
                        name="email"
                        data-valid="valid_mail"
                        type="text"
                        class="Fields-input contact-email"
                        data-view="newemail"
                        formControlName="email"
                        data-di-id="primaryEmailShipp"
                        maxlength="241"
                      />
                      <span
                        *ngIf="
                          !!contactsForm.get('shippingNewContactData.email')
                            .value &&
                          !contactsForm.get('shippingNewContactData.email')
                            .valid &&
                          contactsForm.get('shippingNewContactData.email')
                            .touched
                        "
                        class="errorMessage contacts"
                      >
                        {{
                          'accountRegistration.form.email.email_validation_msg'
                            | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          contactsForm.get('shippingNewContactData.email')
                            .errors?.required &&
                          !contactsForm.get('shippingNewContactData.email')
                            .valid &&
                          contactsForm.get('shippingNewContactData.email')
                            .touched
                        "
                        class="errorMessage contacts"
                        >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="" id="preferredContactFaxShipping">
                  <label
                    for="preferredContactFaxShipping"
                    class="Fields-label mt0 res_lab"
                  >
                    {{
                      'accountRegistration.contactsInformation.fax_label'
                        | cxTranslate
                    }}
                  </label>
                  <div
                    class="custom-control custom-radio custom-control-inline radio_grp label-location ptl"
                  >
                    <input
                      type="radio"
                      id="preferredFaxShipping"
                      name="preferredContactMethod3"
                      class="custom-control-input"
                      [value]="preferredContactMethod[1]"
                      formControlName="preferredContactMethod3"
                    />
                    <label
                      class="custom-control-label secloct"
                      for="preferredFaxShipping"
                    >
                    </label>
                    <div style="display: inline">
                      <input
                        id="preferredFaxShipp"
                        name="fax"
                        data-valid="valid_fax"
                        placeholder="xxx-xxx-xxxx"
                        type="text"
                        class="Fields-input contact-email"
                        data-view="newfax"
                        formControlName="fax"
                        maxlength="12"
                        appPhoneMask
                        data-di-id="preferredFaxShipp"
                      />
                      <span
                        *ngIf="
                          !!contactsForm.get('shippingNewContactData.fax')
                            .value &&
                          !contactsForm.get('shippingNewContactData.fax')
                            .valid &&
                          contactsForm.get('shippingNewContactData.fax').touched
                        "
                        class="errorMessage contacts"
                      >
                        {{
                          'accountRegistration.form.office_fax.fax_validation_msg'
                            | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          contactsForm.get('shippingNewContactData.fax').errors
                            ?.required &&
                          !contactsForm.get('shippingNewContactData.fax')
                            .valid &&
                          contactsForm.get('shippingNewContactData.fax').touched
                        "
                        class="errorMessage contacts"
                        >{{ 'accountRegistration.requiredMsg' | cxTranslate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 p0 c-text-input">
                <label for="shippingPhone" class="Fields-label"
                  >{{ 'accountRegistration.form.phone.label' | cxTranslate
                  }}<span>{{
                    'accountRegistration.optional_tag' | cxTranslate
                  }}</span></label
                >
                <input
                  id="shippingPhone"
                  name="phone"
                  data-valid="valid_phone"
                  placeholder="xxx-xxx-xxxx"
                  type="text"
                  maxlength="12"
                  appPhoneMask
                  class="Fields-input phone-no-mask field-optional"
                  formControlName="phone"
                  data-di-id="shippingPhone"
                />

                <span
                  class="errorMessage"
                  *ngIf="
                    !!contactsForm.get('shippingNewContactData.phone').value &&
                    !contactsForm.get('shippingNewContactData.phone').valid &&
                    contactsForm.get('shippingNewContactData.phone').touched
                  "
                  >{{
                    'accountRegistration.form.phone.ph_validation_msg'
                      | cxTranslate
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Other New Section Ends -->
        <hr class="c-text-input-line billingPage" />
        <div class="invoice-btngrps d-inline-flex gap-1">
          <button
            type="button"
            (click)="back()"
            class="c-button c-button--secondary"
            data-di-id="di-id-7a6cb2cd-cb37948a"
          >
            {{ 'accountRegistration.form.back_button' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="c-button c-button--primary"
            data-di-id="di-id-7a6cb2cd-cb37948a"
          >
            {{ 'accountRegistration.form.submit_button' | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
