import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsmActions, StateWithAsm } from '@spartacus/asm/core';
import { TokenTarget } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthService,
  OAuthLibWrapperService,
  OCC_USER_ID_CURRENT,
  UserIdService,
} from '@spartacus/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomRegistrationService } from 'src/app/custom-user-registration/custom-registration.service';
import { VscaAsmAuthStorageService } from '../service/vsca-asm-auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VscaCsAgentAuthService {
  constructor(
    protected authService: AuthService,
    protected authStorageService: VscaAsmAuthStorageService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected store: Store<StateWithAsm>,
    protected accountRegService: CustomRegistrationService
  ) {}

  protected destroyed$ = new Subject();
  /**
   * Loads access token for a customer support agent.
   * @param userId
   * @param password
   */

  /**
   * Starts an ASM customer emulation session.
   * A customer emulation session is stopped by calling logout().
   * @param customerId
   */
  public startCustomerEmulationSession(customerId: string): void {
    this.authStorageService.clearEmulatedUserToken();

    // OCC specific user id handling. Customize when implementing different backend
    this.store.dispatch(new AuthActions.Logout());
    this.userIdService.setUserId(customerId);
    this.store.dispatch(new AuthActions.Login());
  }

  /**
   * Check if CS agent is currently logged in.
   *
   * @returns observable emitting true when CS agent is logged in or false when not.
   */
  public isCustomerSupportAgentLoggedIn(): Observable<boolean> {
    return combineLatest([
      this.authStorageService.getToken(),
      this.authStorageService.getTokenTarget(),
    ]).pipe(
      map(([token, tokenTarget]) =>
        Boolean(token?.access_token && tokenTarget === TokenTarget.CSAgent)
      )
    );
  }

  /**
   * Utility function to determine if customer is emulated.
   *
   * @returns observable emitting true when there is active emulation session or false when not.
   */
  public isCustomerEmulated(): Observable<boolean> {
    return this.userIdService.isEmulated();
  }

  /**
   * Returns the customer support agent's token loading status
   */
  public getCustomerSupportAgentTokenLoading(): Observable<boolean> {
    // TODO(#8248): Create new loading state outside of store
    return of(false);
  }

  /**
   * Logout a customer support agent.
   */
  async logoutCustomerSupportAgent(): Promise<void> {
    const emulatedToken = this.authStorageService.getEmulatedUserToken();

    let isCustomerEmulated;
    this.userIdService
      .isEmulated()
      .subscribe((emulated) => (isCustomerEmulated = emulated))
      .unsubscribe();

    await this.oAuthLibWrapperService.revokeAndLogout();

    this.store.dispatch(new AsmActions.LogoutCustomerSupportAgent());
    this.authStorageService.setTokenTarget(TokenTarget.User);

    if (isCustomerEmulated && emulatedToken) {
      this.store.dispatch(new AuthActions.Logout());
      this.authStorageService.setToken(emulatedToken);
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);
      this.authStorageService.clearEmulatedUserToken();
      this.store.dispatch(new AuthActions.Login());
    } else {
      this.authService.logout();
    }
  }
}
