import { NgModule } from '@angular/core';
import { CartPageEventModule } from '@spartacus/cart/base/core';
import {
  CheckoutComponentsModule, CheckoutLoginModule
} from '@spartacus/checkout/base/components';
import {
  CheckoutCoreModule
} from '@spartacus/checkout/base/core';
import {
  CheckoutOccModule
} from '@spartacus/checkout/base/occ';
import {
  AnonymousConsentsModule, AuthModule, CostCenterOccModule, ExternalRoutesModule, ProductModule,
  ProductOccModule, UserModule, UserOccModule
} from '@spartacus/core';
import {
  OrderCancellationModule, OrderDetailsModule, OrderHistoryModule,


  //OrderConfirmationModule,
  OrderReturnModule,



  ReplenishmentOrderDetailsModule, ReplenishmentOrderHistoryModule, ReturnRequestDetailModule, ReturnRequestListModule
} from '@spartacus/order/components';
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule
} from '@spartacus/storefront';
import { AddressBookModule } from "@spartacus/user/profile/components";
import { VscaAuthModule } from 'src/core/auth/vsca-auth.module';
import { VscaAsmModule } from '../sanofi-asm/vsca.asm.module';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { VscaUserFeatureModule } from './features/user/vsca-user-feature.module';

@NgModule({
  declarations: [],
  imports: [
    /* Auth Core */
    VscaAuthModule.forRoot(),
    LogoutModule, // will become part of auth package
    VscaAsmModule,
    LoginRouteModule, // will become part of auth package

    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,

    // User Core
    //UserTransitionalModule,
    //UserOccTransitionalModule,
    // User UI
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,

    // Anonymous Consents Core
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,

    // Product Core
    ProductModule.forRoot(),
    ProductOccModule,

    // Product UI
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,

    // Cart Core
    /*CartModule.forRoot(),
    CartOccModule,*/
    // Cart UI
    /*CartComponentModule,
    WishListModule,



    CostCenterOccModule, */
    // Checkout UI

    // Checkout Core
    CheckoutComponentsModule,
    CheckoutLoginModule,
    CheckoutOccModule,
    CheckoutCoreModule,

    // Order
    OrderHistoryModule,
    OrderDetailsModule,
    OrderCancellationModule,
    //OrderConfirmationModule,
    OrderReturnModule,
    ReturnRequestListModule,
    ReturnRequestDetailModule,
    ReplenishmentOrderHistoryModule,
    ReplenishmentOrderDetailsModule,

    // Page Events
    NavigationEventModule,
    HomePageEventModule,
    CartPageEventModule,
    ProductPageEventModule,
    SmartEditFeatureModule,
    VscaUserFeatureModule,
    AsmFeatureModule,
    AuthModule.forRoot(),
    UserModule,
    UserOccModule,
    CostCenterOccModule,
    ExternalRoutesModule.forRoot(),
  ],
  providers: [],
})
export class SpartacusFeaturesModule { }
