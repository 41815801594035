import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { BaseSiteService, CmsService, GlobalMessageService, GlobalMessageType, LanguageService } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { AddCardService } from 'src/app/shared/modules/add-card/add-card.service';
import { MultiFactorAuthService } from 'src/app/shared/services/multi-factor-auth.service';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';
import { EditCard } from './payment';
import { globalErrorMsgEnglish, globalErrorMsgFrench, siteLanguagesEnglish } from './payment.constants';
const inlineblk = 'inline-block';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  monthArray: any = [];
  yearArray: any = [];
  selected: any;
  cmsRespons: any;
  contentMsg: any;
  updateArray: any = [];
  userCardDataArray: any = [];
  indexOfRadioTrue: any;
  selectedExpiryMonth: any;
  selectedExpiryYear: any;
  editIsClicked: boolean;
  updateIsClicked: boolean;
  cancelIsClicked: boolean;
  smallDevices: boolean;
  paymentIsClicked: boolean;
  editDisabled = false;
  request: EditCard;
  cardDefault = true;
  cardIndex: any;
  msg: string;
  french: string;
  userIdDetailsSubscriber: any;
  cardDetailsSubscriber: any;
  deleteCardSubscriber: any;
  updateCardSubscriber: any;
  isAsmUser = false;
  baseSiteId = '';
  
  constructor(
    private readonly modalService: NgbModal,
    private readonly addCardService: AddCardService,
    private readonly ref: ChangeDetectorRef,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly globalMessage: GlobalMessageService,
    private readonly datePipe: DatePipe,
    private readonly sanitizer: DomSanitizer,
    private readonly cmsData: CmsService,
    private readonly languageService: LanguageService,
    protected csAgentAuthService: CsAgentAuthService,
    protected mfaService: MultiFactorAuthService,
    protected baseSiteService: BaseSiteService
  ) { }
  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {
      this.msg = langRes === siteLanguagesEnglish ? globalErrorMsgEnglish : globalErrorMsgFrench;
    });
    const currentYr = new Date().getFullYear();
    this.getCardDetailsOfUser();
    this.formExpiryYears(currentYr);
    const monthOne = 1;
    const lastMonth = 12;
    const ipadWidth = 768;

    for (let i = monthOne; i <= lastMonth; i++) {
      this.monthArray.push(i);
    }
    if (window.screen.width <= ipadWidth) {
      this.smallDevices = true;
    } else {
      this.smallDevices = false;
    }

    this.cmsData
      .getComponentData('AssistanceTextComponent')
      .subscribe((res) => {
        this.cmsRespons = res;
        this.contentMsg = this.cmsRespons.content;
      });
      this.addCardService.cardAdded.subscribe((res)=>{
        if(!!res){
          this.getCardDetailsOfUser()
        }
      
      })

    this.csAgentAuthService.isCustomerEmulated().pipe(take(1)).subscribe((res) => {
      this.isAsmUser = res ? res : false;
    });
    this.baseSiteService.getActive().pipe(take(1)).subscribe(res => { this.baseSiteId = res });

    this.ref.detectChanges();
  }
  ngOnDestroy() {
    window.removeEventListener('resize', () => { });
    this.userIdDetailsSubscriber?.unsubscribe();
    this.cardDetailsSubscriber?.unsubscribe();
    this.deleteCardSubscriber?.unsubscribe();
    this.updateCardSubscriber?.unsubscribe();
  }
  cmsValueSanetize(){
    return  this.sanitizer.bypassSecurityTrustHtml(this.contentMsg);
  }

  formExpiryYears(yr) {
    this.yearArray.push(yr);
    const minYear = 1;
    const maxYr = 21;
    for (let i = minYear; i < maxYr; i++) {
      this.yearArray.push(Number(yr) + i);
    }
  }

  getCardDetailsOfUser() {
    this.cardDefault = true;
    this.pfService.getAccountIdFromDb();
    this.userIdDetailsSubscriber = this.pfService.userAccountIdDetails.subscribe((data) => {
      this.userIdDetailsSubscriber?.unsubscribe();
      this.pfService.getCardFromDb(data.defaultB2BUnit.uid);
    });
    this.cardDetailsSubscriber = this.pfService.cardDetails.subscribe((data) => {
      if (data) {
        this.userCardDataArray = data.sanofiPaymentsResponseList;
        this.userCardDataArray.forEach((element) => {
          if (element.creditCardDefault === true) {
            this.editDisabled = true;
          }
        });

        this.ref.detectChanges();
        this.setBtnsInTable();
      }
      this.cardDetailsSubscriber?.unsubscribe();
    });
  }

  deleteCard(paymentdetailsId, creditCardDefault) {
    if ((this.userCardDataArray.length > 1 && creditCardDefault == false) || (this.userCardDataArray.length == 1 )) {
      this.pfService.deleteCardFromDb(paymentdetailsId);
      this.deleteCardSubscriber = this.pfService.deleteCard.subscribe((res) => {
        if (res.deletePaymentDetails === true) {
          this.getCardDetailsOfUser();
        }
        else {
          window.scroll(0, 0);
          this.globalMessage.add(res.errorMessage, GlobalMessageType.MSG_TYPE_ERROR);
        }
        this.deleteCardSubscriber?.unsubscribe();
      });
    }
    else {
      window.scroll(0, 0);
      this.globalMessage.add(this.msg, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  setBtnsInTable() {

    if (
      this.userCardDataArray !== undefined &&
      this.userCardDataArray !== null &&
      this.userCardDataArray.length > 0
    ) {
      const minValue = 6;
      const maxValue = 10;
      this.userCardDataArray.forEach((element, index) => {
        element.lastFourCardDigits = element.cardNumber.substring(
          minValue,
          maxValue
        );
        if (element.creditCardDefault === true) {
          this.indexOfRadioTrue = index;
        }
        const editBtnDiv = document.getElementById(`editBtn${index}`);
        const updateBtnDiv = document.getElementById(`updateBtn${index}`);
        const cancelBtnDiv = document.getElementById(`cancelBtn${index}`);
        const expirySlctDrpDwn = document.getElementById(
          `expirySlctDrpDwn${index}`
        );
        const radioBtnDiv = document.getElementById(`radioBtn${index}`);
        if (
          editBtnDiv !== null &&
          updateBtnDiv !== null &&
          cancelBtnDiv !== null &&
          expirySlctDrpDwn !== null &&
          radioBtnDiv !== null
        ) {
          document.getElementById(`editBtn${index}`).style.display =
            'inline-block';
          document.getElementById(`updateBtn${index}`).style.display = 'none';
          document.getElementById(`cancelBtn${index}`).style.display = 'none';
          document.getElementById(`expirySlctDrpDwn${index}`).style.display =
            'none';

          const HTMLInputRadio = document.getElementById(`radioBtn${index}`) as HTMLInputElement;
          HTMLInputRadio.classList.add('disabled');
        }
      });
    }
    this.ref.detectChanges();
  }

  onEdit(i) {
    this.request = {
      accountHolderName: i.creditCardName,
      cardType: { code: i.cardType },
      cardNumber: i.cardNumber,
      defaultPaymentInfo: true,
      expiryMonth: i.expDate.split('/')[0],
      expiryYear: i.expDate.split('/')[1],
      id: i.cardId,
    };


  }

  editCard(index) {
    this.cardIndex = index;
    this.cardDefault = false;
    this.editIsClicked = true;
    document.getElementById(`editBtn${index}`).style.display = 'none';
    document.getElementById(`updateBtn${index}`).style.display = inlineblk;
    document.getElementById(`cancelBtn${index}`).style.display = inlineblk;

    const htmlInput = document.getElementById(`radioBtn${index}`) as HTMLInputElement;

    htmlInput.classList.remove('disabled');

    this.selectedExpiryMonth =
      new Date(this.userCardDataArray[index].validTo).getMonth() + 1;
    this.selectedExpiryYear = new Date(
      this.userCardDataArray[index].validTo
    ).getFullYear();
  }

  updateCardDetails(index, paymentdetailsId) {
    this.editIsClicked = false;
    const radioElement = document.getElementById(`radioBtn${this.indexOfRadioTrue}`) as HTMLInputElement;
    const radioChecked = radioElement.checked;

    if (radioChecked) {
      radioElement.checked = true;
    }
    this.disableFunctions(index);
    this.pfService.editCardDetails(paymentdetailsId, this.request);
    this.updateCardSubscriber = this.pfService.updateCardDetails.subscribe((res) => {
      if (res.updatePaymentDetails === true) {
        this.getCardDetailsOfUser();
      } else {
        window.scroll(0, 0);
        this.globalMessage.add(res.errorMessage, GlobalMessageType.MSG_TYPE_ERROR);
      }
      this.updateCardSubscriber?.unsubscribe();
    });
  }

  cancelCardEdit(index) {
    this.editIsClicked = false;

    this.disableFunctions(index);

    const HtmlElement = document.getElementById(`radioBtn${this.indexOfRadioTrue}`) as HTMLInputElement;
    HtmlElement.checked = true;
  }

  disableFunctions(index) {
    document.getElementById(`updateBtn${index}`).style.display = 'none';
    document.getElementById(`cancelBtn${index}`).style.display = 'none';
    document.getElementById(`expirySlctDrpDwn${index}`).style.display = 'none';
    document.getElementById(`expiryDisplay${index}`).style.display = inlineblk;
    document.getElementById(`editBtn${index}`).style.display = inlineblk;

    const HTMLInput = document.getElementById(`radioBtn${index}`) as HTMLInputElement;
    HTMLInput.classList.add('disabled');
  }

  openMFA() {
    this.addCardService.addCardWithMFA();
   }


}
