import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { translationChunksConfig } from '@spartacus/assets';
import { I18nModule, provideConfig } from '@spartacus/core';
import { OtpModalComponent } from './otp-modal/otp-modal.component';



@NgModule({
  declarations: [OtpModalComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class OtpModalModule { }
