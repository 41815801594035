import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class OpenStatementsService {
  public reloadComponent = new Subject<boolean>();
  public loadStatements = new Subject<any>();
  public userAccountIdDetails = new Subject<any>();

  constructor(private readonly baseService: BaseService) {}

  public getStatements(params): Observable<any> {
    return this.baseService.get(config.GET_OPEN_STATEMENTS, params);
  }

  public getAllStatements(params): Observable<any> {
    return this.baseService.get(config.GET_ALL_STATEMENTS_DATA_RANGE, params);
  }
  public getAccountId() {
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.userAccountIdDetails.next(res);
      }
    });
  }
}
